import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const GrantListPage = () =>
  import('@/modules/grant/components/grant-list-page.vue');
const GrantFormPage = () =>
  import('@/modules/grant/components/grant-form-page.vue');
const GrantViewPage = () =>
  import('@/modules/grant/components/grant-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'grant',
        path: '/grant',
        component: GrantListPage,
        meta: {
          auth: true,
          permission: Permissions.values.grantNavigate,
        },
      },
      {
        name: 'grantNew',
        path: '/grant/new',
        component: GrantFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.grantNavigate,
        },
      },
      {
        name: 'grantEdit',
        path: '/grant/:id/edit',
        component: GrantFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.grantNavigate,
        },
        props: true,
      },
      {
        name: 'grantView',
        path: '/grant/:id',
        component: GrantViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.grantNavigate,
        },
        props: true,
      },
    ],
  },
];
