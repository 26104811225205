import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class GrantPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.grantRead,
    );
    this.grantAutocomplete = permissionChecker.match(
      Permissions.values.grantAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.grantCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.grantEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.grantDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.grantNavigate,
    );
  }
}
