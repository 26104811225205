import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class CenterInstitutionPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.centerInstitutionRead,
    );
    this.centerInstitutionAutocomplete = permissionChecker.match(
      Permissions.values.centerInstitutionAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.centerInstitutionCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.centerInstitutionEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.centerInstitutionDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.centerInstitutionNavigate,
    );
    
  }
}
