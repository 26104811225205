import importerStore from '@/shared/importer/importer-store';
import { ResearchFocusService } from '@/modules/research-focus/research-focus-service';
import researchFocusImporterFields from '@/modules/research-focus/research-focus-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  ResearchFocusService.import,
  researchFocusImporterFields,
  i18n('entities.researchFocus.importer.fileName'),
  i18n('entities.researchFocus.importer.hint'),
);
