<template>
  <div v-if="!isBlank && hasPermissionToDownload">
    <div :key="item.id" v-for="item in value">
      <a :href="item.downloadUrl" download target="_blank">{{
        truncate(item.name)
      }}</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { truncate } from 'lodash';
import FilePermissionChecker from '@/security/filePermissionChecker';

export default {
  name: 'app-list-item-file',

  props: ['value', 'permissions'],

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

    isBlank() {
      return !this.value || !this.value.length;
    },

    hasPermissionToDownload() {
      return new FilePermissionChecker(this.currentUser).download(
        this.permissions,
      );
    },
  },

  methods: {
    truncate(name) {
      return truncate(name);
    },
  },
};
</script>

<style></style>
