<template>
  <div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
    >
      <el-form-item
        :label="fields.name.label"
        :prop="fields.name.name"
        :required="fields.name.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <el-input
            :disabled="isApproved && !isAdmin"
            v-model="model[fields.name.name]"
            ref="focus"
            type="textarea"
          />
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.comment.label"
        :prop="fields.comment.name"
        :required="fields.comment.required"
        v-if="isEditing"
        :disabled="!isAdmin"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <el-input
            v-model="model[fields.comment.name]"
            ref="focus"
            type="textarea"
            :disabled="!isAdmin"
          />
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.status.label"
        :prop="fields.status.name"
        :disabled="!isAdmin"
        v-if="isEditing"
        ><el-col :lg="12" :md="16" :sm="24">
          <el-select
            placeholder
            v-model="model[fields.status.name]"
            :disabled="!isAdmin"
          >
            <el-option :value="undefined">--</el-option>
            <el-option
              :key="option.id"
              :label="option.label"
              :value="option.id"
              v-for="option in fields.status.options"
            ></el-option>
          </el-select>
        </el-col>
      </el-form-item>

      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading || (isApproved && !isAdmin)"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
            v-if="!modal"
          >
            <app-i18n code="common.save"></app-i18n>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doPropose"
            icon="el-icon-fa-floppy-o"
            type="primary"
            v-if="modal"
          >
            <app-i18n code="common.propose"></app-i18n>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
          >
            <app-i18n code="common.cancel"></app-i18n>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ResearchFocusModel } from '@/modules/research-focus/research-focus-model';

const { fields } = ResearchFocusModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.comment,
  fields.status,
  fields.updatedAt,
]);

export default {
  name: 'app-research-focus-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      isAdmin: 'auth/isAdmin',
    }),

    isApproved() {
      return this.model.status === 'approved';
    },

    fields() {
      return fields;
    },
  },

  methods: {
    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      const data = await this.validateForm();
      return this.$emit('submit', data);
    },

    async doPropose() {
      const data = await this.validateForm();
      data.values.status = 'proposed';
      return this.$emit('submit', data);
    },

    async validateForm() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      let { id, ...values } = formSchema.cast(this.model);

      return { id, values, routeToHome: !this.isAdmin };
    },
  },
};
</script>

<style>
</style>
