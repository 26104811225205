import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class ProjectTeamPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.projectTeamRead,
    );
    this.projectTeamAutocomplete = permissionChecker.match(
      Permissions.values.projectTeamAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.projectTeamCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.projectTeamEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.projectTeamDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.projectTeamNavigate,
    );
  }
}
