import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import { UserField } from '@/modules/auth/user-field';

function label(name) {
  return i18n(`entities.humanResource.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  contactEmail: new StringField(
    'contactEmail',
    label('contactEmail'),
    {
      required: true,
      matches: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      max: 255,
    },
  ),
  contactPerson: new StringField(
    'contactPerson',
    label('contactPerson'),
    {
      required: true,
      max: 255,
    },
  ),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), {
    required: false,
    matches: /^[0-9]/,
    max: 24,
  }),
  user: UserField.relationToOne('user', label('user'), {
    required: true,
  }),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class HumanResourceModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
