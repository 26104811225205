import facultyListStore from '@/modules/faculty/faculty-list-store';
import facultyViewStore from '@/modules/faculty/faculty-view-store';
import facultyFormStore from '@/modules/faculty/faculty-form-store';
import facultyDestroyStore from '@/modules/faculty/faculty-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: facultyDestroyStore,
    form: facultyFormStore,
    list: facultyListStore,
    view: facultyViewStore,
  },
};
