import authAxios from '@/shared/axios/auth-axios';
import { AuthToken } from '@/modules/auth/auth-token';

export default class AuthService {
  static async sendEmailVerification() {
    const response = await authAxios.post(
      '/auth/send-email-address-verification-email',
    );

    return response.data;
  }

  static async sendPasswordResetEmail(email) {
    const response = await authAxios.post('/auth/send-password-reset-email', {
      email,
    });

    return response.data;
  }

  static async registerWithEmailAndPassword(email, password) {
    const response = await authAxios.post('/auth/sign-up', {
      email,
      password,
    });

    return response.data;
  }

  static async signinWithEmailAndPassword(email, password) {
    const response = await authAxios.post('/auth/sign-in', {
      email,
      password,
    });

    return response.data;
  }

  static async fetchMe() {
    const response = await authAxios.get('/auth/me');
    return response.data;
  }

  static async isEmailConfigured() {
    const response = await authAxios.get('/auth/email-configured');
    return response.data;
  }

  static signout() {
    AuthToken.clearTokens();
  }

  static async updateProfile(data) {
    const body = {
      data,
    };

    const response = await authAxios.put('/auth/profile', body);

    return response.data;
  }
  static async passwordReset(token, password) {
    const response = await authAxios.put('/auth/password-reset', {
      token,
      password,
    });

    return response.data;
  }

  static async verifyEmail(token) {
    const response = await authAxios.put('/auth/verify-email', {
      token,
    });

    return response.data;
  }

  static async createUserAfterSignup(data) {
    const body = {
      data,
    };

    const response = await authAxios.post('/auth/create-signup', body);
    return response.data;
  }

  static async changePassword(oldPassword, newPassword) {
    const response = await authAxios.put('/auth/change-password', {
      oldPassword,
      newPassword,
    });

    return response.data;
  }

  static async initialPasswordChange(password) {
    const response = await authAxios.put('/auth/password-initial-change', {
      password,
    });

    return response.data;
  }
}
