import projectImplementationFormStore from '@/modules/project-implementation/project-implementation-form-store';
import projectImplementationViewStore from '@/modules/project-implementation/project-implementation-view-store';

export default {
  namespaced: true,

  modules: {
    form: projectImplementationFormStore,
    view: projectImplementationViewStore,
  },
};
