import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const CooperationPartnerListPage = () =>
  import('@/modules/cooperation-partner/components/cooperation-partner-list-page.vue');
const CooperationPartnerFormPage = () =>
  import('@/modules/cooperation-partner/components/cooperation-partner-form-page.vue');
const CooperationPartnerViewPage = () =>
  import('@/modules/cooperation-partner/components/cooperation-partner-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'cooperationPartner',
        path: '/cooperation-partner',
        component: CooperationPartnerListPage,
        meta: {
          auth: true,
          permission: Permissions.values.cooperationPartnerNavigate,
        },
      },
      {
        name: 'cooperationPartnerNew',
        path: '/cooperation-partner/new',
        component: CooperationPartnerFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.cooperationPartnerNavigate,
        },
      },
      {
        name: 'cooperationPartnerEdit',
        path: '/cooperation-partner/:id/edit',
        component: CooperationPartnerFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.cooperationPartnerNavigate,
        },
        props: true,
      },
      {
        name: 'cooperationPartnerView',
        path: '/cooperation-partner/:id',
        component: CooperationPartnerViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.cooperationPartnerNavigate,
        },
        props: true,
      },
    ],
  },
];
