<template>
  <div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
    >
      <el-form-item
        :label="fields.teamMember.label"
        :prop="fields.teamMember.name"
        :required="fields.teamMember.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <app-team-member-autocomplete-input
            :fetchFn="fields.teamMember.fetchFn"
            :mapperFn="fields.teamMember.mapperFn"
            :parentId="model[fields.id.name]"
            mode="multiple"
            v-model="model[fields.teamMember.name]"
          ></app-team-member-autocomplete-input>
        </el-col>
      </el-form-item>

      <app-team-member-form-project-team-page
        ref="appTeamMemberFormPage"
        v-bind="model[fields.teamMember.name]"
        v-model="model[fields.teamMember.name]"
      ></app-team-member-form-project-team-page>

      <el-form-item
        :label="fields.projectManager.label"
        :prop="fields.projectManager.name"
        :required="fields.projectManager.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <app-project-manager-autocomplete-input
            :fetchFn="fields.projectManager.fetchFn"
            :mapperFn="fields.projectManager.mapperFn"
            :parentId="model[fields.id.name]"
            mode="multiple"
            v-model="model[fields.projectManager.name]"
          ></app-project-manager-autocomplete-input>
        </el-col>
      </el-form-item>

      <app-project-manager-form-project-team-page
        ref="appProjectManagerFormPage"
        v-bind="model[fields.projectManager.name]"
        v-model="model[fields.projectManager.name]"
      ></app-project-manager-form-project-team-page>

      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
          >
            <app-i18n code="common.save"></app-i18n>
          </el-button>
          <!--
          <el-button :disabled="saveLoading" @click="doCancel" icon="el-icon-fa-close">
            <app-i18n code="common.cancel"></app-i18n>
          </el-button>
          -->
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ProjectTeamModel } from '@/modules/project-team/project-team-model';
import CreateTeamMemberFormPage from '@/modules/team-member/components/team-member-form-project-team-page';
import ProjectCreateManagerFormPage from '@/modules/project-manager/components/project-manager-form-project-team-page';
import TeamMemeberListTable from '@/modules/team-member/components/team-member-list-table';
import ProjectManagerListTable from '@/modules/project-manager/components/project-manager-list-table';
import { EventBus } from '@/eventBus';

const { fields } = ProjectTeamModel;
const formSchema = new FormSchema([
  fields.id,
  fields.teamMember,
  fields.projectManager,
  fields.updatedAt,
]);

export default {
  name: 'app-project-team-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  components: {
    [CreateTeamMemberFormPage.name]: CreateTeamMemberFormPage,
    [ProjectCreateManagerFormPage.name]: ProjectCreateManagerFormPage,
    [TeamMemeberListTable.name]: TeamMemeberListTable,
    [ProjectManagerListTable.name]: ProjectManagerListTable,
  },

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      values: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  mounted() {
    EventBus.$on('doSubmitProjectTeam', () => {
      this.doSubmit();
    });
  },

  watch: {
    record: function () {
      this.doUpdateModel();
    },
  },

  beforeDestroy() {
    EventBus.$off('doSubmitProjectTeam');
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);

      this.values = values;

      return this.$emit('submit', {
        id,
        values,
      });
    },

    doUpdateModel() {
      this.model = formSchema.initialValues(this.record || {});
    },
  },
};
</script>

<style>
</style>
