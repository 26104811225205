import { ProjectCreateService } from '@/modules/project-create/project-create-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
    projectTeamId: null,
    isUpdateOfProjectTeam: false,
    id: null,
    formModel: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    status: (state, getters) => {
      if (getters.record) {
        return getters.record.status;
      } else { return null }
    },
    projectTeamId: (state) => state.projectTeamId,
    isUpdateOfProjectTeam: (state) => state.isUpdateOfProjectTeam,
    id: (state) => state.id,
    formModel: (state) => state.formModel,
    pendingFiles: (state, getters) => {
      if (!getters.formModel)
        return [];

      let unsaved = [];
      const timeSheet = getters.formModel.timeSheet.filter(f => f.new);
      const costsPlan = getters.formModel.costsPlan.filter(f => f.new);
      const descriptionThirdPartyFunding = getters.formModel.descriptionThirdPartyFunding.filter(f => f.new);
      const financialGuideline = getters.formModel.financialGuideline.filter(f => f.new);
      unsaved = unsaved.concat(timeSheet);
      unsaved = unsaved.concat(costsPlan);
      unsaved = unsaved.concat(descriptionThirdPartyFunding);
      unsaved = unsaved.concat(financialGuideline);
      return unsaved;
    },
  },

  mutations: {
    RESET(state) {
      state.record = null;
      state.projectTeamId = null;
      state.isUpdateOfProjectTeam = null;
      state.id = null;
      state.formModel = null;
      state.findLoading = false;
      state.saveLoading = false;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.projectTeamId = null;
      state.id = null;
      state.formModel = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.projectTeamId = payload.projectTeamId ? payload.projectTeamId : null;
      state.id = payload.id;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.projectTeamId = null;
      state.id = null;
      state.formModel = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state, payload) {
      state.record = payload;
      state.projectTeamId = payload.projectTeamId ? payload.projectTeamId : null;
      state.id = payload.id;
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state, payload) {
      state.record = payload;
      state.projectTeamId = payload.projectTeamId ? payload.projectTeamId : null;
      state.id = payload.id;
      state.saveLoading = false;
      state.isUpdateOfProjectTeam = false;
      state.formModel = null;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
      state.isUpdateOfProjectTeam = false;
    },

    SET_FORM_MODEL(state, payload) {
      state.formModel = payload;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doSetFormModel({ commit }, payload) {
      commit('SET_FORM_MODEL', payload)
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await ProjectCreateService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/project-create');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        const record = await ProjectCreateService.create(values);
        commit('CREATE_SUCCESS', record);
        Message.success(i18n('entities.projectCreate.create.success'));
        return record.id;
        //routerAsync().push('/project-create');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        const record = await ProjectCreateService.update(id, values);
        commit('UPDATE_SUCCESS', record);
        Message.success(i18n('entities.projectCreate.update.success'));
        return record;
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
