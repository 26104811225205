<template>
  <div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
    >
      <el-form-item>
        <el-col :lg="13" :md="16" :sm="24">
          <el-radio-group v-model="radio">
            <el-radio-button :change="doReset" label="person"
              >Person</el-radio-button
            >
            <el-radio-button :change="doReset" label="institution"
              >Institution</el-radio-button
            >
          </el-radio-group>
        </el-col>
      </el-form-item>

      <div v-if="radio === 'person'">
        <el-form-item
          :label="fields.firstName.label"
          :prop="fields.firstName.name"
          :required="fields.firstName.required"
          key="firstName-input"
        >
          <el-col :lg="13" :md="16" :sm="24">
            <el-input
              ref="focus"
              v-model="model[fields.firstName.name]"
            />
          </el-col>
        </el-form-item>
        <el-form-item
          :label="fields.lastName.label"
          :prop="fields.lastName.name"
          :required="fields.lastName.required"
          key="lastName-input"
        >
          <el-col :lg="13" :md="16" :sm="24">
            <el-input v-model="model[fields.lastName.name]" />
          </el-col>
        </el-form-item>

        <el-form-item
          :label="fields.title.label"
          :prop="fields.title.name"
          :required="fields.title.required"
          key="title-input"
        >
          <el-col :lg="13" :md="16" :sm="24">
            <el-input v-model="model[fields.title.name]" />
          </el-col>
        </el-form-item>

        <el-form-item
          :label="fields.affiliation.label"
          :prop="fields.affiliation.name"
          :required="fields.affiliation.required"
          key="affiliation-input"
        >
          <el-col :lg="13" :md="16" :sm="24">
            <el-input v-model="model[fields.affiliation.name]" />
          </el-col>
        </el-form-item>
      </div>

      <div v-else>
        <el-form-item
          :label="fields.institutionName.label"
          :prop="fields.institutionName.name"
          :required="fields.institutionName.required"
          key="institutionName-input"
        >
          <el-col :lg="13" :md="16" :sm="24">
            <el-input v-model="model[fields.institutionName.name]" />
          </el-col>
        </el-form-item>

        <el-form-item
          :label="fields.address.label"
          :prop="fields.address.name"
          :required="fields.address.required"
          key="address-input"
        >
          <el-col :lg="13" :md="16" :sm="24">
            <el-input v-model="model[fields.address.name]" />
          </el-col>
        </el-form-item>

        <el-form-item
          :label="fields.contactPerson.label"
          :prop="fields.contactPerson.name"
          :required="fields.contactPerson.required"
          key="contactPerson-input"
        >
          <el-col :lg="13" :md="16" :sm="24">
            <el-input v-model="model[fields.contactPerson.name]" />
          </el-col>
        </el-form-item>

        <el-form-item
          :label="fields.webAddress.label"
          :prop="fields.webAddress.name"
          :required="fields.webAddress.required"
          key="webAddress-input"
        >
          <el-col :lg="13" :md="16" :sm="24">
            <el-input v-model="model[fields.webAddress.name]" />
          </el-col>
        </el-form-item>
      </div>

      <el-form-item
        :label="fields.emailAddress.label"
        :prop="fields.emailAddress.name"
        :required="fields.emailAddress.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <el-input v-model="model[fields.emailAddress.name]" />
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.phoneNumber.label"
        :prop="fields.phoneNumber.name"
        :required="fields.phoneNumber.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <el-input v-model="model[fields.phoneNumber.name]" />
        </el-col>
      </el-form-item>

      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
          >
            <app-i18n code="common.save"></app-i18n>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
          >
            <app-i18n code="common.cancel"></app-i18n>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { CooperationPartnerModel } from '@/modules/cooperation-partner/cooperation-partner-model';

const { fields } = CooperationPartnerModel;
const formSchema = new FormSchema([
  fields.id,
  fields.lastName,
  fields.firstName,
  fields.title,
  fields.affiliation,
  fields.emailAddress,
  fields.phoneNumber,
  fields.institutionName,
  fields.address,
  fields.contactPerson,
  fields.webAddress,
  fields.partner,
  fields.updatedAt,
]);

export default {
  name: 'app-cooperation-partner-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      radio: 'person',
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});

    if (this.model[fields.partner.name]) {
      this.radio = this.model[fields.partner.name];
    }
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    doCancel() {
      this.$emit('cancel');
    },

    doReset() {7
      this.model = formSchema.initialValues(this.record || {});
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      this.model[fields.partner.name] = this.radio;
      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },
  },
};
</script>

<style>
</style>
