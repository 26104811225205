import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { UserField } from '@/modules/auth/user-field';
import { GenericModel } from '@/shared/model/generic-model';
import { FacultyField } from '@/modules/faculty/faculty-field';

function label(name) {
  return i18n(`entities.viceDeanResearch.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  faculty: FacultyField.relationToOne('faculty', label('faculty'), {}),
  user: UserField.relationToMany('user', label('dean'), {
    "required": true,
  }),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export class ViceDeanResearchModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
