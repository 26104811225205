import projectReportFormStore from '@/modules/project-report/project-report-form-store';
import projectReportViewStore from '@/modules/project-report/project-report-view-store';

export default {
  namespaced: true,

  modules: {
    form: projectReportFormStore,
    view: projectReportViewStore,
  },
};
