import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class HumanResourcePermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.humanResourceRead,
    );
    this.humanResourceAutocomplete = permissionChecker.match(
      Permissions.values.humanResourceAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.humanResourceCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.humanResourceEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.humanResourceDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.humanResourceNavigate,
    );
  }
}
