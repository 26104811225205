import cooperationPartnerContractListStore from '@/modules/cooperation-partner-contract/cooperation-partner-contract-list-store';
import cooperationPartnerContractFormStore from '@/modules/cooperation-partner-contract/cooperation-partner-contract-form-store';
import cooperationPartnerContractDestroyStore from '@/modules/cooperation-partner-contract/cooperation-partner-contract-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: cooperationPartnerContractDestroyStore,
    form: cooperationPartnerContractFormStore,
    list: cooperationPartnerContractListStore,
  },
};
