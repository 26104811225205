import grantListStore from '@/modules/grant/grant-list-store';
import grantViewStore from '@/modules/grant/grant-view-store';
import grantFormStore from '@/modules/grant/grant-form-store';
import grantDestroyStore from '@/modules/grant/grant-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: grantDestroyStore,
    form: grantFormStore,
    list: grantListStore,
    view: grantViewStore,    
  },
};
