import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import StringField from '@/shared/fields/string-field';
import { GenericModel } from '@/shared/model/generic-model';
import { TeamMemberField } from '@/modules/team-member/team-member-field';
import { ProjectManagerField } from '@/modules/project-manager/project-manager-field';
import { ProjectField } from '@/modules/project/project-field';

function label(name) {
  return i18n(`entities.projectTeam.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  teamMember: TeamMemberField.relationToMany('teamMember', label('teamMember'), {}),
  projectManager: ProjectManagerField.relationToMany('projectManager', label('projectManager'), {}),
  project: ProjectField.relationToOne('project', label('project'), {}),
  projectID: new StringField('projectID', label('projectID'), {}),
  manager: new StringField('manager', label('manager'), {}),
  team: new StringField('team', label('team'), {}),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export class ProjectTeamModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
