import { ProjectManagerService } from '@/modules/project-manager/project-manager-service';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import RelationToManyField from '@/shared/fields/relation-to-many-field';
import Permissions from '@/security/permissions';

export class ProjectManagerField {
  static relationToOne(name, label, options) {
    return new RelationToOneField(
      name,
      label,
      '/project-manager',
      Permissions.values.projectManagerRead,
      Permissions.values.projectManagerNavigate,
      ProjectManagerService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        return {
          id: record.id,
          label: `${record.user.fullName} <${record.user.email}>`,
        };
      },
      options,
    );
  }

  static relationToMany(name, label, options) {
    return new RelationToManyField(
      name,
      label,
      '/project-manager',
      Permissions.values.projectManagerRead,
      Permissions.values.projectManagerNavigate,
      ProjectManagerService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        return {
          id: record.id,
          label: `${record.user.fullName} <${record.user.email}>`,
        };
      },
      options,
    );
  }
}
