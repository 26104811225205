import projectGanttFormStore from '@/modules/project-gantt/project-gantt-form-store';
import projectGanttViewStore from '@/modules/project-gantt/project-gantt-view-store';
export default {
  namespaced: true,

  modules: {
    form: projectGanttFormStore,
    view: projectGanttViewStore,
  },
};
