import { i18n } from '@/i18n';
import { SettingsStorage } from '@/modules/settings/settings-storage';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import { GenericModel } from '@/shared/model/generic-model';
import { FundingAgencyField } from '@/modules/funding-agency/funding-agency-field';
import StringField from '@/shared/fields/string-field';
import DateRangeField from '@/shared/fields/date-range-field';
import DateField from '@/shared/fields/date-field';
import DecimalField from '@/shared/fields/decimal-field';
import FilesField from '@/shared/fields/files-field';
import { ProjectFundingField } from '@/modules/project-funding/project-funding-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';

function label(name) {
  return i18n(`entities.grant.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.grant.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  projectFunding: ProjectFundingField.relationToOne(
    'projectFunding',
    label('projectFunding'),
    {},
  ),
  fundingAgency: FundingAgencyField.relationToOne(
    'fundingAgency',
    label('fundingAgency'),
    {
      required: true,
    },
  ),
  contactPerson: new StringField(
    'contactPerson',
    label('contactPerson'),
    {
      required: true,
    },
  ),
  tenderUrl: new StringField('tenderUrl', label('tenderUrl'), {}),
  deadlineForSubmission: new DateField(
    'deadlineForSubmission',
    label('deadlineForSubmission'),
    {},
  ),
  availabilityOfGrantorDecision: new DateField(
    'availabilityOfGrantorDecision',
    label('availabilityOfGrantorDecision'),
    {},
  ),
  projectDuration: new DateRangeField(
    'projectDuration',
    label('projectDuration'),
    {
      required: true,
    },
  ),
  decision: new EnumeratorField(
    'decision',
    label('decision'),
    [
      {
        id: 'positive',
        label: enumeratorLabel('decision', 'positive'),
      },
      {
        id: 'negative',
        label: enumeratorLabel('decision', 'negative'),
      },
    ],
    {},
  ),
  amount: new DecimalField('amount', label('amount'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: true,
  }),
  decisionFile: new FilesField(
    'decisionFile',
    label('decisionFile'),
    Storage.values.grantDecisionFile,
    Permissions.getFilePermissions('grantFileFields', 'decisionFile'),
    {
      max: SettingsStorage.getByName('MAX_UPLOAD_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  status: new EnumeratorField(
    'status',
    label('status'),
    [
      {
        id: 'notificationSent',
        label: enumeratorLabel('status', 'notificationSent'),
      },
    ],
    {},
  ),

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class GrantModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
