import cooperationPartnerListStore from '@/modules/cooperation-partner/cooperation-partner-list-store';
import cooperationPartnerViewStore from '@/modules/cooperation-partner/cooperation-partner-view-store';
import cooperationPartnerFormStore from '@/modules/cooperation-partner/cooperation-partner-form-store';
import cooperationPartnerDestroyStore from '@/modules/cooperation-partner/cooperation-partner-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: cooperationPartnerDestroyStore,
    form: cooperationPartnerFormStore,
    list: cooperationPartnerListStore,
    view: cooperationPartnerViewStore,    
  },
};
