import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class FundingAgencyPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.fundingAgencyRead,
    );
    this.fundingAgencyAutocomplete = permissionChecker.match(
      Permissions.values.fundingAgencyAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.fundingAgencyCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.fundingAgencyEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.fundingAgencyDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.fundingAgencyNavigate,
    );
  }
}
