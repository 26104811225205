<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
    >
      <app-cooperation-partner-contract-form
        :modal="true"
        :record="record"
        :saveLoading="saveLoading"
        @cancel="doCancel"
        @submit="doSubmit"
      />
    </el-dialog>
  </div>
</template>

<script>
import cooperationPartnerContractForm from '@/modules/cooperation-partner-contract/components/cooperation-partner-contract-form';
import Errors from '@/shared/error/errors';
import { CooperationPartnerContractService } from '@/modules/cooperation-partner-contract/cooperation-partner-contract-service';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { mapGetters } from 'vuex';
import { i18n } from '@/i18n';

export default {
  name: 'app-cooperation-partner-contract-form-modal',

  props: ['visible', 'projectImplementationId', 'record'],

  components: {
    [cooperationPartnerContractForm.name]: cooperationPartnerContractForm,
  },

  data() {
    return {
      //record: null,
      saveLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      pendingFiles: 'cooperationPartnerContract/form/pendingFiles',
    }),

    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    isEditing() {
      return !!this.record;
    },

    title() {
      return this.isEditing
        ? i18n('entities.cooperationPartnerContract.edit.title')
        : i18n('entities.cooperationPartnerContract.new.title');
    },
  },

  methods: {
    async doSubmit(payload) {
      try {
        let record;
        this.saveLoading = true;

        if (this.isEditing) {
          record = await CooperationPartnerContractService.update(
            payload.id,
            payload.values,
          );
        } else {
          if (this.projectImplementationId) {
            //set projectImplementationId so that is linked to cooperation contract
            payload.values.projectImplementation = this.projectImplementationId;
          }
          record = await CooperationPartnerContractService.create(
            payload.values,
          );
        }

        this.$emit('success', record);
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.saveLoading = false;
      }
    },

    async doCancel() {
      //if there are any pending temp files delete them from the server
      if (this.pendingFiles.length > 0) {
        await FileUploader.removePendingFiles(this.pendingFiles);
      }
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
</style>
