import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const cooperationPartnerContractListPage = () =>
  import('@/modules/cooperation-partner-contract/components/cooperation-partner-contract-list-page.vue');
const cooperationPartnerContractListReleaseRequestPage = () =>
  import('@/modules/cooperation-partner-contract/components/cooperation-partner-contract-list-release-request-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'cooperationPartnerContract',
        path: '/cooperation-partner-contract',
        component: cooperationPartnerContractListPage,
        meta: {
          auth: true,
          permission: Permissions.values.cooperationPartnerContractNavigate,
        },
      },
      {
        name: 'cooperationPartnerContractRelease',
        path: '/cooperation-partner-contract/:id/release',
        component: cooperationPartnerContractListReleaseRequestPage,
        meta: {
          auth: true,
          permission: Permissions.values.cooperationPartnerContractRelease,
        },
        props: true,
      },
    ],
  },
];
