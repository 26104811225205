import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import DateRangeField from '@/shared/fields/date-range-field';
import { ProjectGanttField } from '@/modules/project-gantt/project-gantt-field'

function label(name) {
  return i18n(`entities.workPackage.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true,
    "max": 255
  }),
  milestone: new DateRangeField('milestone', label('milestone'), {
    required: true,
  }),
  projectGantt: new ProjectGanttField.relationToOne('projectGantt', label('projectGantt'),),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export class WorkPackageModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
