import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class ProjectManagerPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.projectManagerRead,
    );
    this.projectManagerAutocomplete = permissionChecker.match(
      Permissions.values.projectManagerAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.projectManagerCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.projectManagerEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.projectManagerDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.projectManagerNavigate,
    );
  }
}
