import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class TemplatePermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.templateRead,
    );
    this.templateAutocomplete = permissionChecker.match(
      Permissions.values.templateAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.templateCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.templateEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.templateDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.templateNavigate,
    );
  }
}
