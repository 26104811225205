import { i18n } from '@/i18n';
import { SettingsStorage } from '@/modules/settings/settings-storage';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import EnumeratorField from '@/shared/fields/enumerator-field';
import DateField from '@/shared/fields/date-field';
import DateRangeField from '@/shared/fields/date-range-field';
import StringField from '@/shared/fields/string-field';
import { TeamMemberField } from '@/modules/team-member/team-member-field';
import { ProjectImplementationField } from '@/modules/project-implementation/project-implementation-field';
import { ProjectField } from '@/modules/project/project-field';
import FilesField from '@/shared/fields/files-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';

function label(name) {
  return i18n(`entities.employmentContract.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(
    `entities.employmentContract.enumerators.${name}.${value}`,
  );
}

const fields = {
  id: new IdField('id', label('id')),
  project: new ProjectField.relationToOne(
    'project',
    label('project'),
    {},
  ),
  teamMember: TeamMemberField.relationToOne(
    'teamMember',
    label('teamMember'),
    {
      required: true,
    },
  ),
  projectImplementation: ProjectImplementationField.relationToOne(
    'projectImplementation',
    label('projectImplementation'),
    {},
  ),
  contractDraft: new FilesField(
    'contractDraft',
    label('contractDraft'),
    Storage.values.employmentContractContractDraft,
    Permissions.getFilePermissions(
      'employmentContractFileFields',
      'contractDraft',
    ),
    {
      required: true,
      max: SettingsStorage.getByName('MAX_UPLOAD_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  contract: new FilesField(
    'contract',
    label('contract'),
    Storage.values.employmentContractContract,
    Permissions.getFilePermissions(
      'employmentContractFileFields',
      'contract',
    ),
    {
      required: true,
      max: SettingsStorage.getByName('MAX_UPLOAD_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  status: new EnumeratorField(
    'status',
    label('status'),
    [
      {
        id: 'released',
        label: enumeratorLabel('status', 'released'),
      },
      { id: 'revise', label: enumeratorLabel('status', 'revise') },
      {
        id: 'releaseRequested',
        label: enumeratorLabel('status', 'releaseRequested'),
      },
    ],
    {},
  ),
  contractType: new StringField(
    'contractType',
    label('contractType'),
    {
      required: true,
      max: 100,
    },
  ),
  date: new DateField('date', label('date'), {}),

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
  dateRange: new DateRangeField('dateRange', label('dateRange')),
};

export class EmploymentContractModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
