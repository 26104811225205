import humanResourceListStore from '@/modules/human-resource/human-resource-list-store';
import humanResourceViewStore from '@/modules/human-resource/human-resource-view-store';
import humanResourceFormStore from '@/modules/human-resource/human-resource-form-store';
import humanResourceDestroyStore from '@/modules/human-resource/human-resource-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: humanResourceDestroyStore,
    form: humanResourceFormStore,
    list: humanResourceListStore,
    view: humanResourceViewStore,
  
  },
};
