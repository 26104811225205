import Vue from 'vue';
import Vuex from 'vuex';
import ProgressBar from '@/shared/progress-bar/progress-bar';
import Router from 'vue-router';
import shared from '@/shared/shared-module';
import auth from '@/modules/auth/auth-module';
import layout from '@/modules/layout/layout-module';
import home from '@/modules/home/home-module';
import iam from '@/modules/iam/iam-module';
import settings from '@/modules/settings/settings-module';
import auditLog from '@/modules/audit-log/audit-log-module';
import project from '@/modules/project/project-module';
import projectCreate from '@/modules/project-create/project-create-module';
import projectRelease from '@/modules/project-release/project-release-module';
import projectReleaseRequest from '@/modules/project-release-request/project-release-request-module';
import projectImplementation from '@/modules/project-implementation/project-implementation-module';
import projectFunding from '@/modules/project-funding/project-funding-module';
import projectReport from '@/modules/project-report/project-report-module';
import projectNote from '@/modules/project-note/project-note-module';
import faculty from '@/modules/faculty/faculty-module';
import location from '@/modules/location/location-module';
import centerInstitution from '@/modules/center-institution/center-institution-module';
import outpatientClinic from '@/modules/outpatient-clinic/outpatient-clinic-module';
import subjectArea from '@/modules/subject-area/subject-area-module';
import researchFocus from '@/modules/research-focus/research-focus-module';
import fundingAgency from '@/modules/funding-agency/funding-agency-module';
import cooperationPartner from '@/modules/cooperation-partner/cooperation-partner-module';
import grant from '@/modules/grant/grant-module';
import viceDeanResearch from '@/modules/vice-dean-research/vice-dean-research-module';
import chancellor from '@/modules/chancellor/chancellor-module';
import legalDepartment from '@/modules/legal-department/legal-department-module';
import humanResource from '@/modules/human-resource/human-resource-module';
import teamMember from '@/modules/team-member/team-member-module';
import projectTeam from '@/modules/project-team/project-team-module';
import projectManager from '@/modules/project-manager/project-manager-module';
import cooperationPartnerContract from '@/modules/cooperation-partner-contract/cooperation-partner-contract-module';
import employmentContract from '@/modules/employment-contract/employment-contract-module';
import projectGantt from '@/modules/project-gantt/project-gantt-module';
import workPackage from '@/modules/work-package/work-package-module';
import projectCompletion from '@/modules/project-completion/project-completion-module';
import template from '@/modules/template/template-module';
import knowledgeBase from '@/modules/knowledge-base/knowledge-base-module';

const modules = {
  shared,
  home,
  settings,
  auth,
  iam,
  auditLog,
  layout,
  project,
  projectCreate,
  projectRelease,
  projectReleaseRequest,
  projectImplementation,
  projectFunding,
  projectReport,
  projectNote,
  faculty,
  location,
  centerInstitution,
  outpatientClinic,
  subjectArea,
  researchFocus,
  fundingAgency,
  cooperationPartner,
  viceDeanResearch,
  chancellor,
  legalDepartment,
  humanResource,
  teamMember,
  projectTeam,
  projectManager,
  cooperationPartnerContract,
  employmentContract,
  grant,
  projectGantt,
  workPackage,
  projectCompletion,
  template,
  knowledgeBase,
};

// start - boilerplate code

const exists = (el) => !!el;

function setupComponentsFiltersDirectivesAndMixins() {
  Object.keys(modules)
    .map((key) => modules[key].components)
    .filter(exists)
    .forEach((components) => {
      components.forEach((component) => {
        Vue.component(component.name, component);
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].filters)
    .filter(exists)
    .forEach((components) => {
      components.forEach((filter) => {
        Vue.filter(filter.name, filter.implementation);
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].directives)
    .filter(exists)
    .forEach((directives) => {
      directives.forEach((directive) => {
        Vue.directive(
          directive.name,
          directive.implementation,
        );
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].mixins)
    .filter(exists)
    .forEach((mixins) => {
      mixins.forEach((mixin) => {
        Vue.mixin(mixin);
      });
    });
}

const routes = [
  ...Object.keys(modules)
    .filter((key) => !!modules[key].routes)
    .map((key) => modules[key].routes)
    .reduce((a, b) => a.concat(b), []),
  { path: '*', redirect: '/404' },
];

let router = null;

const routerAsync = () => {
  if (!router) {
    router = new Router({
      mode: 'history',
      routes,
      scrollBehavior() {
        return { x: 0, y: 0 };
      },
    });

    router.beforeEach((to, from, next) => {
      if (to.name) {
        ProgressBar.start();
      }

      next();
    });

    router.afterEach(() => {
      ProgressBar.done();
    });
  }

  return router;
};

const buildStores = () => {
  const output = {};

  Object.keys(modules)
    .filter((key) => !!modules[key].store)
    .forEach((key) => {
      output[key] = modules[key].store;
    });

  return output;
};

let store = null;

const storeAsync = () => {
  if (!store) {
    store = new Vuex.Store({ modules: buildStores() });
  }

  return store;
};

export {
  setupComponentsFiltersDirectivesAndMixins,
  routerAsync,
  storeAsync,
};

// end - boilerplate code
