import { ProjectNoteService } from '@/modules/project-note/project-note-service';
import Errors from '@/shared/error/errors';
import { i18n } from '@/i18n';
import Message from '@/shared/message/message';

export default {
  namespaced: true,

  state: {
    listLoading: false,
    saveLoading: false,
    notes: null,
  },

  getters: {
    listLoading: (state) => !!state.listLoading,
    saveLoading: (state) => !!state.saveLoading,
    notes: (state) => state.notes,
  },

  mutations: {
    RESET(state) {
      state.listLoading = false;
      state.saveLoading = false;
      state.notes = null;
    },
    FETCH_STARTED(state) {
      state.notes = null;
      state.listLoading = true;
    },

    FETCH_SUCCESS(state, payload) {
      state.notes = payload;
      state.listLoading = false;
    },

    FETCH_ERROR(state) {
      state.notes = null;
      state.listLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state, payload) {
      state.notes = payload;
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    async doFetch({ commit }, projectId) {
      try {
        commit('FETCH_STARTED');
        const records = await ProjectNoteService.list(projectId);
        commit('FETCH_SUCCESS', records);
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        const records = await ProjectNoteService.create(values);
        commit('CREATE_SUCCESS', records);
        Message.success(i18n('entities.project.createNote.success'));
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },
  },
};
