import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const ChancellorListPage = () =>
  import('@/modules/chancellor/components/chancellor-list-page.vue');
const ChancellorFormPage = () =>
  import('@/modules/chancellor/components/chancellor-form-page.vue');
const ChancellorViewPage = () =>
  import('@/modules/chancellor/components/chancellor-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'chancellor',
        path: '/chancellor',
        component: ChancellorListPage,
        meta: {
          auth: true,
          permission: Permissions.values.chancellorNavigate,
        },
      },
      {
        name: 'chancellorNew',
        path: '/chancellor/new',
        component: ChancellorFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.chancellorNavigate,
        },
      },
      {
        name: 'chancellorEdit',
        path: '/chancellor/:id/edit',
        component: ChancellorFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.chancellorNavigate,
        },
        props: true,
      },
      {
        name: 'chancellorView',
        path: '/chancellor/:id',
        component: ChancellorViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.chancellorNavigate,
        },
        props: true,
      },
    ],
  },
];
