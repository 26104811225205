import viceDeanResearchListStore from '@/modules/vice-dean-research/vice-dean-research-list-store';
import viceDeanResearchViewStore from '@/modules/vice-dean-research/vice-dean-research-view-store';
import viceDeanResearchFormStore from '@/modules/vice-dean-research/vice-dean-research-form-store';
import viceDeanResearchDestroyStore from '@/modules/vice-dean-research/vice-dean-research-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: viceDeanResearchDestroyStore,
    form: viceDeanResearchFormStore,
    list: viceDeanResearchListStore,
    view: viceDeanResearchViewStore,    
  },
};
