import centerInstitutionListStore from '@/modules/center-institution/center-institution-list-store';
import centerInstitutionViewStore from '@/modules/center-institution/center-institution-view-store';
import centerInstitutionFormStore from '@/modules/center-institution/center-institution-form-store';
import centerInstitutionDestroyStore from '@/modules/center-institution/center-institution-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: centerInstitutionDestroyStore,
    form: centerInstitutionFormStore,
    list: centerInstitutionListStore,
    view: centerInstitutionViewStore,    
  },
};
