/**
 * List of project create statuses.
 */
class ProjectStep {
    static get values() {
      return { 
        create: 'create',
        release: 'release',
        funding: 'funding',
        gantt: 'gantt',
        implementation: 'implementation',
        report: 'report',
        completion: 'completion',
      };
    }
    
    static get ids() {
      return { 
        create: 0,
        release: 1,
        funding: 2,
        gantt: 3,
        implementation: 4,
        report: 5,
        completion: 6,
      };
    }
  }
  
  module.exports = ProjectStep;
