import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const FundingAgencyListPage = () =>
  import('@/modules/funding-agency/components/funding-agency-list-page.vue');
const FundingAgencyFormPage = () =>
  import('@/modules/funding-agency/components/funding-agency-form-page.vue');
const FundingAgencyViewPage = () =>
  import('@/modules/funding-agency/components/funding-agency-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'fundingAgency',
        path: '/funding-agency',
        component: FundingAgencyListPage,
        meta: {
          auth: true,
          permission: Permissions.values.fundingAgencyNavigate,
        },
      },
      {
        name: 'fundingAgencyNew',
        path: '/funding-agency/new',
        component: FundingAgencyFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.fundingAgencyNavigate,
        },
      },
      {
        name: 'fundingAgencyEdit',
        path: '/funding-agency/:id/edit',
        component: FundingAgencyFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.fundingAgencyNavigate,
        },
        props: true,
      },
      {
        name: 'fundingAgencyView',
        path: '/funding-agency/:id',
        component: FundingAgencyViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.fundingAgencyNavigate,
        },
        props: true,
      },
    ],
  },
];
