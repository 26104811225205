let settings = {};

export class SettingsStorage {
  static async get() {
    return settings || null;
  }

  static getByName(name) {
    return settings[`${name}`];
  }

  static async save(data) {
    //write to memory variable
    settings.MAX_UPLOAD_FILE_SIZE = data.MAX_UPLOAD_FILE_SIZE;
    settings.MAX_AVATAR_FILE_SIZE = data.MAX_AVATAR_FILE_SIZE;
    settings.MAX_TEMPLATES_FILE_SIZE = data.MAX_TEMPLATES_FILE_SIZE;
  }

  static async clear() {
    settings = {};
    /*  localStorage.setItem('geringfuegigkeitsgrenze', '');
    localStorage.setItem('hbglProTag', '');
    localStorage.setItem('hbglProMonat', '');
    localStorage.setItem('hbglProJahr', ''); */
  }
}
