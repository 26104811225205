import { ProjectFundingService } from '@/modules/project-funding/project-funding-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    requestAccountingProjectNumberAllocationLoading: false,
    record: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    status: (state, getters) => getters.record.status,
    isThirdPartyFundedProject: (state, getters) => getters.record.fundingEnum === 'thirdPartyFunding',
    isSelfFinancedProject: (state, getters) => getters.record.fundingEnum === 'selfFinancedProject',
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state, payload) {
      state.record = payload;
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state, payload) {
      state.record = payload;
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },

    REQUEST_ACCOUNTING_PROJECT_NUMBER_ALLOCATION_STARTED(state) {
      state.requestAccountingProjectNumberAllocationLoading = true;
    },

    REQUEST_ACCOUNTING_PROJECT_NUMBER_ALLOCATION_SUCCESS(state, payload) {
      state.record = payload;
      state.requestAccountingProjectNumberAllocationLoading = false;
    },

    REQUEST_ACCOUNTING_PROJECT_NUMBER_ALLOCATION_ERROR(state) {
      state.requestAccountingProjectNumberAllocationLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');

        const record = await ProjectFundingService.find(id);

        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        const record = await ProjectFundingService.create(values);
        commit('CREATE_SUCCESS', record);
        Message.success(i18n('entities.projectFunding.create.success'));
        //routerAsync().push('/project-funding');
        return record.id;
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        const record = await ProjectFundingService.update(id, values);
        commit('UPDATE_SUCCESS', record);
        Message.success(i18n('entities.projectFunding.update.success'));
        return record;
        //routerAsync().push('/project-funding');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doUpdateStatus({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        const record = await ProjectFundingService.updateStatus(id, values);
        commit('UPDATE_SUCCESS', record);
        return record;
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doRequestAssignmentNumberByAccounting({ commit }, id) {
      try {
        commit('REQUEST_ACCOUNTING_PROJECT_NUMBER_ALLOCATION_STARTED');
        const record = await ProjectFundingService.requestAllocationOfProjectNumberByAccounting(id);
        commit('REQUEST_ACCOUNTING_PROJECT_NUMBER_ALLOCATION_SUCCESS', record);
        Message.success(i18n('entities.projectFunding.requestAccountingProjectNumberAllocation.success'));
      } catch (error) {
        Errors.handle(error);
        commit('REQUEST_ACCOUNTING_PROJECT_NUMBER_ALLOCATION_ERROR');
      }
    },
  },
};
