import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const EmploymentContractListPage = () =>
  import('@/modules/employment-contract/components/employment-contract-list-page.vue');
const EmploymentContractListReleaseRequestPage = () =>
  import('@/modules/employment-contract/components/employment-contract-list-release-request-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'employmentContract',
        path: '/employment-contract',
        component: EmploymentContractListPage,
        meta: {
          auth: true,
          permission: Permissions.values.employmentContractNavigate,
        },
      },
      {
        name: 'employmentContractRelease',
        path: '/employment-contract/:id/release',
        component: EmploymentContractListReleaseRequestPage,
        meta: {
          auth: true,
          permission: Permissions.values.employmentContractRelease,
        },
        props: true,
      },
    ],
  },
];
