<template>
  <el-aside :width="asideWidth">
    <!--
    <h1 class="logo">
      <router-link to="/">
        <app-i18n code="app.title"></app-i18n>
      </router-link>
    </h1>
-->
    <el-menu
      :class="{
        'el-menu-side-nav': true,
        collapsed: collapsed,
      }"
      :collapse="collapsed"
      :collapse-transition="true"
      :router="true"
      @select="collapseMenuIfMobile()"
    >
      <el-menu-item
        :class="classFor('/', true)"
        :route="{ path: '/' }"
        index="/"
      >
        <i class="el-icon-fa-home"></i>
        <span slot="title">
          <app-i18n code="home.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/project')"
        :route="{ path: '/project' }"
        index="/project"
        v-if="hasPermissionToProject"
      >
        <i class="el-icon-fa-folder"></i>
        <span slot="title">
          <app-i18n code="entities.project.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/project-team')"
        :route="{ path: '/project-team' }"
        index="/project-team"
        v-if="hasPermissionToProjectTeam"
      >
        <i class="el-icon-fa-users"></i>
        <span slot="title">
          <app-i18n code="entities.projectTeam.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/project-release-request')"
        :route="{ path: '/project-release-request' }"
        index="/project-release-request"
        v-if="hasPermissionToProjectReleaseRequest"
      >
        <i class="el-icon-fa-check-square"></i>
        <span slot="title">
          <app-i18n code="entities.projectReleaseRequest.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/template')"
        :route="{ path: '/template' }"
        index="/temmplate"
        v-if="hasPermissionToTemplate"
      >
        <i class="el-icon-fa-file"></i>
        <span slot="title">
          <app-i18n code="entities.template.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/grant')"
        :route="{ path: '/grant' }"
        index="/grant"
        v-if="hasPermissionToGrant"
      >
        <i class="el-icon-fa-credit-card"></i>
        <span slot="title">
          <app-i18n code="entities.grant.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/faculty')"
        :route="{ path: '/faculty' }"
        index="/faculty"
        v-if="hasPermissionToFaculty"
      >
        <i class="el-icon-fa-graduation-cap"></i>
        <span slot="title">
          <app-i18n code="entities.faculty.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/location')"
        :route="{ path: '/location' }"
        index="/location"
        v-if="hasPermissionToLocation"
      >
        <i class="el-icon-fa-map-marker"></i>
        <span slot="title">
          <app-i18n code="entities.location.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/center-institution')"
        :route="{ path: '/center-institution' }"
        index="/center-institution"
        v-if="hasPermissionToCenterInstitution"
      >
        <i class="el-icon-fa-building"></i>
        <span slot="title">
          <app-i18n code="entities.centerInstitution.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/outpatient-clinic')"
        :route="{ path: '/outpatient-clinic' }"
        index="/outpatient-clinic"
        v-if="hasPermissionToOutpatientClinic"
      >
        <i class="el-icon-fa-plus-square"></i>
        <span slot="title">
          <app-i18n code="entities.outpatientClinic.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/subject-area')"
        :route="{ path: '/subject-area' }"
        index="/subject-area"
        v-if="hasPermissionToSubjectArea"
      >
        <i class="el-icon-fa-list-ul"></i>
        <span slot="title">
          <app-i18n code="entities.subjectArea.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/research-focus')"
        :route="{ path: '/research-focus' }"
        index="/research-focus"
        v-if="hasPermissionToResearchFocus"
      >
        <i class="el-icon-fa-list"></i>
        <span slot="title">
          <app-i18n code="entities.researchFocus.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/funding-agency')"
        :route="{ path: '/funding-agency' }"
        index="/funding-agency"
        v-if="hasPermissionToFundingAgency"
      >
        <i class="el-icon-fa-list-alt"></i>
        <span slot="title">
          <app-i18n code="entities.fundingAgency.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/cooperation-partner')"
        :route="{ path: '/cooperation-partner' }"
        index="/cooperation-partner"
        v-if="hasPermissionToCooperationPartner"
      >
        <i class="el-icon-fa-handshake-o"></i>
        <span slot="title">
          <app-i18n code="entities.cooperationPartner.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/vice-dean-research')"
        :route="{ path: '/vice-dean-research' }"
        index="/vice-dean-research"
        v-if="hasPermissionToViceDeanResearch"
      >
        <i class="el-icon-fa-user"></i>
        <span slot="title">
          <app-i18n code="entities.viceDeanResearch.menu"></app-i18n>
        </span>
      </el-menu-item>

      <!--  <el-menu-item
        :class="classFor('/chancellor')"
        :route="{ path: '/chancellor' }"
        index="/chancellor"
        v-if="hasPermissionToChancellor"
      >
        <i class="el-icon-fa-user"></i>
        <span slot="title">
          <app-i18n code="entities.chancellor.menu"></app-i18n>
        </span>
      </el-menu-item> -->

      <!-- <el-menu-item
        :class="classFor('/legal-department')"
        :route="{ path: '/legal-department' }"
        index="/legal-department"
        v-if="hasPermissionToLegalDepartment"
      >
        <i class="el-icon-fa-user"></i>
        <span slot="title">
          <app-i18n code="entities.legalDepartment.menu"></app-i18n>
        </span>
      </el-menu-item> -->

      <!-- <el-menu-item
        :class="classFor('/human-resource')"
        :route="{ path: '/human-resource' }"
        index="/human-resource"
        v-if="hasPermissionToHumanResource"
      >
        <i class="el-icon-fa-user"></i>
        <span slot="title">
          <app-i18n code="entities.humanResource.menu"></app-i18n>
        </span>
      </el-menu-item> -->

      <el-menu-item
        :class="classFor('/cooperation-partner-contract')"
        :route="{ path: '/cooperation-partner-contract' }"
        index="/cooperation-partner-contract"
        v-if="hasPermissionToCooperationPartnerContract"
      >
        <i class="el-icon-fa-file-text"></i>
        <span slot="title">
          <app-i18n code="entities.cooperationPartnerContract.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/employment-contract')"
        :route="{ path: '/employment-contract' }"
        index="/employment-contract"
        v-if="hasPermissionToEmploymentContract"
      >
        <i class="el-icon-fa-file-text"></i>
        <span slot="title">
          <app-i18n code="entities.employmentContract.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/knowledge-base')"
        :route="{ path: '/knowledge-base' }"
        index="/knowledge-base"
        v-if="hasPermissionToKnowledgeBase"
      >
        <i class="el-icon-fa-question-circle"></i>
        <span slot="title">
          <app-i18n code="entities.knowledgeBase.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/iam')"
        :route="{ path: '/iam' }"
        index="/iam"
        v-if="hasPermissionToIam"
      >
        <i class="el-icon-fa-user-plus"></i>
        <span slot="title">
          <app-i18n code="iam.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/audit-logs')"
        :route="{ path: '/audit-logs' }"
        index="/audit-logs"
        v-if="hasPermissionToAuditLog"
      >
        <i class="el-icon-fa-history"></i>
        <span slot="title">
          <app-i18n code="auditLog.menu"></app-i18n>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/settings')"
        :route="{ path: '/settings' }"
        index="/settings"
        v-if="hasPermissionToSettings"
      >
        <i class="el-icon-setting"></i>
        <span slot="title">
          <app-i18n code="settings.menu"></app-i18n>
        </span>
      </el-menu-item>
    </el-menu>
  </el-aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SettingsPermissions } from '@/modules/settings/settings-permissions';
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { IamPermissions } from '@/modules/iam/iam-permissions';
import { ProjectPermissions } from '@/modules/project/project-permissions';
import { ProjectReleaseRequestPermissions } from '@/modules/project-release-request/project-release-request-permissions';
import { GrantPermissions } from '@/modules/grant/grant-permissions';
import { FacultyPermissions } from '@/modules/faculty/faculty-permissions';
import { LocationPermissions } from '@/modules/location/location-permissions';
import { CenterInstitutionPermissions } from '@/modules/center-institution/center-institution-permissions';
import { OutpatientClinicPermissions } from '@/modules/outpatient-clinic/outpatient-clinic-permissions';
import { SubjectAreaPermissions } from '@/modules/subject-area/subject-area-permissions';
import { ResearchFocusPermissions } from '@/modules/research-focus/research-focus-permissions';
import { FundingAgencyPermissions } from '@/modules/funding-agency/funding-agency-permissions';
import { CooperationPartnerPermissions } from '@/modules/cooperation-partner/cooperation-partner-permissions';
import { ViceDeanResearchPermissions } from '@/modules/vice-dean-research/vice-dean-research-permissions';
import { ChancellorPermissions } from '@/modules/chancellor/chancellor-permissions';
import { LegalDepartmentPermissions } from '@/modules/legal-department/legal-department-permissions';
import { HumanResourcePermissions } from '@/modules/human-resource/human-resource-permissions';
import { ProjectTeamPermissions } from '@/modules/project-team/project-team-permissions';
import { CooperationPartnerContractPermissions } from '@/modules/cooperation-partner-contract/cooperation-partner-contract-permissions';
import { EmploymentContractPermissions } from '@/modules/employment-contract/employment-contract-permissions';
import { WorkPackagePermissions } from '@/modules/work-package/work-package-permissions';
import { TemplatePermissions } from '@/modules/template/template-permissions';
import { KnowledgeBasePermissions } from '@/modules/knowledge-base/knowledge-base-permissions';

export default {
  name: 'app-menu',

  computed: {
    ...mapGetters({
      collapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),

    hasPermissionToSettings() {
      return new SettingsPermissions(this.currentUser).navigate;
    },
    hasPermissionToAuditLog() {
      return new AuditLogPermissions(this.currentUser).navigate;
    },
    hasPermissionToIam() {
      return new IamPermissions(this.currentUser).navigate;
    },
    hasPermissionToProject() {
      return new ProjectPermissions(this.currentUser).navigate;
    },
    hasPermissionToProjectReleaseRequest() {
      return new ProjectReleaseRequestPermissions(this.currentUser).navigate;
    },
    hasPermissionToGrant() {
      return new GrantPermissions(this.currentUser).navigate;
    },
    hasPermissionToFaculty() {
      return new FacultyPermissions(this.currentUser).navigate;
    },
    hasPermissionToLocation() {
      return new LocationPermissions(this.currentUser).navigate;
    },
    hasPermissionToCenterInstitution() {
      return new CenterInstitutionPermissions(this.currentUser).navigate;
    },
    hasPermissionToOutpatientClinic() {
      return new OutpatientClinicPermissions(this.currentUser).navigate;
    },
    hasPermissionToSubjectArea() {
      return new SubjectAreaPermissions(this.currentUser).navigate;
    },
    hasPermissionToResearchFocus() {
      return new ResearchFocusPermissions(this.currentUser).navigate;
    },
    hasPermissionToFundingAgency() {
      return new FundingAgencyPermissions(this.currentUser).navigate;
    },
    hasPermissionToCooperationPartner() {
      return new CooperationPartnerPermissions(this.currentUser).navigate;
    },
    hasPermissionToViceDeanResearch() {
      return new ViceDeanResearchPermissions(this.currentUser).navigate;
    },
    hasPermissionToChancellor() {
      return new ChancellorPermissions(this.currentUser).navigate;
    },
    hasPermissionToLegalDepartment() {
      return new LegalDepartmentPermissions(this.currentUser).navigate;
    },
    hasPermissionToHumanResource() {
      return new HumanResourcePermissions(this.currentUser).navigate;
    },
    hasPermissionToProjectTeam() {
      return new ProjectTeamPermissions(this.currentUser).navigate;
    },
    hasPermissionToCooperationPartnerContract() {
      return new CooperationPartnerContractPermissions(this.currentUser)
        .navigate;
    },
    hasPermissionToEmploymentContract() {
      return new EmploymentContractPermissions(this.currentUser).navigate;
    },
    hasPermissionToWorkPackage() {
      return new WorkPackagePermissions(this.currentUser).navigate;
    },
    hasPermissionToTemplate() {
      return new TemplatePermissions(this.currentUser).navigate;
    },
    hasPermissionToKnowledgeBase() {
      return new KnowledgeBasePermissions(this.currentUser).navigate;
    },

    asideWidth() {
      if (this.isMobile && !this.collapsed) {
        return '100%';
      }
      if (!this.collapsed) {
        return '260px';
      }
      if (this.isMobile && this.collapsed) {
        return '0px';
      }
      return '70px';
    },
  },

  created() {
    this.getElementUiDateFormat();
  },

  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
      getElementUiDateFormat: 'layout/getElementUiDateFormat',
    }),

    collapseMenuIfMobile() {
      if (this.isMobile) {
        this.collapseMenu();
      }
    },

    classFor(path, exact = false) {
      if (exact) {
        return {
          active: this.$route.path === path,
        };
      }

      const routePath = this.$route.path;
      const active = routePath === path || routePath.startsWith(path + '/');

      return {
        active,
      };
    },
  },
};
</script>

<style scoped>
.el-menu.collapsed span {
  visibility: hidden;
}
</style>
