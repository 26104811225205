import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class ResearchFocusPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.researchFocusRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.researchFocusImport,
    );
    this.researchFocusAutocomplete = permissionChecker.match(
      Permissions.values.researchFocusAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.researchFocusCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.researchFocusEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.researchFocusDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.researchFocusNavigate,
    );
  }
}
