import { UserModel } from '@/modules/auth/user-model';

const { fields } = UserModel;

export default [
  fields.id,
  fields.email,
  /* fields.academicTitle, */
  fields.firstName,
  fields.lastName,
  fields.phoneNumber,
  fields.faculty,
  fields.location,
  fields.centerInstitution,
  fields.outpatientClinic,
  fields.roles,
  fields.status,
  fields.createdAt,
];
