import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import { ProjectField } from '@/modules/project/project-field';

function label(name) {
  return i18n(`entities.researchFocus.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.researchFocus.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    "required": true,
    "max": 255
  }),
  project: new ProjectField.relationToMany('project', label('project'), {}),
  status: new EnumeratorField('status', label('status'), [
    { id: 'approved', label: enumeratorLabel('status', 'approved') },
    { id: 'revise', label: enumeratorLabel('status', 'revise') },
    { id: 'proposed', label: enumeratorLabel('status', 'proposed') },
  ], {}),
  comment: new StringField('comment', label('comment'), {
    "required": true,
    "max": 255
  }),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export class ResearchFocusModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
