import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class LegalDepartmentPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.legalDepartmentRead,
    );
    this.legalDepartmentAutocomplete = permissionChecker.match(
      Permissions.values.legalDepartmentAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.legalDepartmentCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.legalDepartmentEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.legalDepartmentDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.legalDepartmentNavigate,
    );
  }
}
