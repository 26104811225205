import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class ChancellorPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.chancellorRead,
    );
    this.chancellorAutocomplete = permissionChecker.match(
      Permissions.values.chancellorAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.chancellorCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.chancellorEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.chancellorDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.chancellorNavigate,
    );
  }
}
