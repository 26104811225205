import { ProjectService } from '@/modules/project/project-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
import ProjectStepEnum from '@/shared/enums/projectStep';
const projectStepEnum = ProjectStepEnum.values;

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
    id: null,
    projectID: null,
    projectCreate: null,
    projectRelease: null,
    projectImplementation: null,
    projectReport: null,
    projectCompletion: null,
    projectFunding: null,
    projectGantt: null,
    projectTeam: null,
    status: null,
    step: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    projectId: (state) => state.id,
    projectID: (state) => state.projectID,
    projectCreateId: (state) => state.projectCreate,
    projectReleaseId: (state) => state.projectRelease,
    projectImplementationId: (state) => state.projectImplementation,
    projectFundingId: (state) => state.projectFunding,
    projectGanttId: (state) => state.projectGantt,
    projectReportId: (state) => state.projectReport,
    projectCompletionId: (state) => state.projectCompletion,
    projectTeamId: (state) => state.projectTeam,
    projectFaculty: (state, getters) => getters.record.projectCreate ?
      getters.record.projectCreate.faculty.map((f) => ({
        id: f.id,
        label: f.name,
      })) : [],
    fundingAgencyEnum: (state, getters) => {
      if (getters.record.projectCreate && getters.record.projectCreate.fundingAgencyEnum != null) {
        return getters.record.projectCreate.fundingAgencyEnum === "yes" ? "thirdPartyFunding" : "selfFinancedProject";
      } else {
        return getters.record.projectCreate.fundingAgencyEnum;
      }
    },

    step: (state) => state.step,
    status: (state) => state.status,
    project: (state) => {
      return {
        projectID: state.projectID,
        projectCreate: state.projectCreate,
        projectRelease: state.projectRelease,
        projectImplementation: state.projectImplementation,
        projectFunding: state.projectFunding,
        projectGantt: state.projectGantt,
        projectReport: state.projectReport,
        projectCompletion: state.projectCompletion,
        projectTeam: state.projectTeam,
        status: state.status,
        step: state.step,
      }
    },
    updatedAt: (state, getters) => {
      return getters.record ? getters.record.updatedAt : null;
    },
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.id = null;
      state.record = null;
      state.projectID = null;
      state.projectCreate = null;
      state.projectRelease = null;
      state.projectImplementation = null;
      state.projectReport = null;
      state.projectCompletion = null;
      state.projectFunding = null;
      state.projectGantt = null;
      state.projectTeam = null;
      state.status = null;
      state.step = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.id = null;
      state.projectID = null;
      state.projectCreate = null;
      state.projectRelease = null;
      state.projectImplementation = null;
      state.projectReport = null;
      state.projectCompletion = null;
      state.projectFunding = null;
      state.projectGantt = null;
      state.projectTeam = null;
      state.status = null;
      state.step = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.id = payload.id ? payload.id : null;
      state.projectID = payload.projectID ? payload.projectID : null;
      state.projectCreate = payload.projectCreate ? payload.projectCreate.id : null;
      state.projectRelease = payload.projectRelease ? payload.projectRelease.id : null;
      state.projectImplementation = payload.projectImplementation ? payload.projectImplementation.id : null;
      state.projectReport = payload.projectReport ? payload.projectReport.id : null;
      state.projectCompletion = payload.projectCompletion ? payload.projectCompletion.id : null;
      state.projectFunding = payload.projectFunding ? payload.projectFunding.id : null;
      state.projectGantt = payload.projectGantt ? payload.projectGantt.id : null;

      if (payload.projectCreate) {
        state.projectTeam = payload.projectCreate.projectTeam ? payload.projectCreate.projectTeam.id : null;
      } else {
        state.projectTeam = null;
      }

      state.status = payload.status;
      state.step = payload.step;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.id = null;
      state.projectID = null;
      state.projectCreate = null;
      state.projectRelease = null;
      state.projectImplementation = null;
      state.projectReport = null;
      state.projectCompletion = null;
      state.projectFunding = null;
      state.projectGantt = null;
      state.projectTeam = null;
      state.status = null;
      state.step = null;
      state.findLoading = false;
    },

    RELOAD_STARTED(state) {
      state.record = null;
      state.id = null;
      state.projectID = null;
      state.projectCreate = null;
      state.projectRelease = null;
      state.projectImplementation = null;
      state.projectReport = null;
      state.projectCompletion = null;
      state.projectFunding = null;
      state.projectGantt = null;
      state.projectTeam = null;
      state.status = null;
      state.step = null;
      state.findLoading = true;
    },

    RELOAD_SUCCESS(state, payload) {
      state.record = payload;
      state.id = payload.id ? payload.id : null;
      state.projectID = payload.projectID ? payload.projectID : null;
      state.projectCreate = payload.projectCreate ? payload.projectCreate.id : null;
      state.projectRelease = payload.projectRelease ? payload.projectRelease.id : null;
      state.projectImplementation = payload.projectImplementation ? payload.projectImplementation.id : null;
      state.projectReport = payload.projectReport ? payload.projectReport.id : null;
      state.projectCompletion = payload.projectCompletion ? payload.projectCompletion.id : null;
      state.projectFunding = payload.projectFunding ? payload.projectFunding.id : null;
      state.projectGantt = payload.projectGantt ? payload.projectGantt.id : null;

      if (payload.projectCreate) {
        state.projectTeam = payload.projectCreate.projectTeam ? payload.projectCreate.projectTeam.id : null;
      } else {
        state.projectTeam = null;
      }

      state.status = payload.status;
      state.step = payload.step;
      state.findLoading = false;
    },

    RELOAD_ERROR(state) {
      state.record = null;
      state.id = null;
      state.projectID = null;
      state.projectCreate = null;
      state.projectRelease = null;
      state.projectImplementation = null;
      state.projectReport = null;
      state.projectCompletion = null;
      state.projectFunding = null;
      state.projectGantt = null;
      state.projectTeam = null;
      state.status = null;
      state.step = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state, payload) {
      state.record = payload;
      state.id = payload.id ? payload.id : null;
      state.projectID = payload.projectID ? payload.projectID : null;
      state.projectCreate = payload.projectCreate ? payload.projectCreate.id : null;
      state.projectRelease = payload.projectRelease ? payload.projectRelease.id : null;
      state.projectImplementation = payload.projectImplementation ? payload.projectImplementation.id : null;
      state.projectReport = payload.projectReport ? payload.projectReport.id : null;
      state.projectCompletion = payload.projectCompletion ? payload.projectCompletion.id : null;
      state.projectFunding = payload.projectFunding ? payload.projectFunding.id : null;
      state.projectGantt = payload.projectGantt ? payload.projectGantt.id : null;

      if (payload.projectCreate) {
        state.projectTeam = payload.projectCreate.projectTeam ? payload.projectCreate.projectTeam.id : null;
      } else {
        state.projectTeam = null;
      }

      state.status = payload.status;
      state.step = payload.step;
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.record = null;
      state.id = null;
      state.projectID = null;
      state.projectCreate = null;
      state.projectRelease = null;
      state.projectImplementation = null;
      state.projectReport = null;
      state.projectCompletion = null;
      state.projectFunding = null;
      state.projectGantt = null;
      state.projectTeam = null;
      state.status = null;
      state.step = null;
      state.findLoading = false;
    },

    UPDATE_SUCCESS(state, payload) {
      state.record = payload;
      state.id = payload.id ? payload.id : null;
      state.projectID = payload.projectID ? payload.projectID : null;
      state.projectCreate = payload.projectCreate ? payload.projectCreate.id : null;
      state.projectRelease = payload.projectRelease ? payload.projectRelease.id : null;
      state.projectImplementation = payload.projectImplementation ? payload.projectImplementation.id : null;
      state.projectReport = payload.projectReport ? payload.projectReport.id : null;
      state.projectCompletion = payload.projectCompletion ? payload.projectCompletion.id : null;
      state.projectFunding = payload.projectFunding ? payload.projectFunding.id : null;
      state.projectGantt = payload.projectGantt ? payload.projectGantt.id : null;

      if (payload.projectCreate) {
        state.projectTeam = payload.projectCreate.projectTeam ? payload.projectCreate.projectTeam.id : null;
      } else {
        state.projectTeam = null;
      }

      state.status = payload.status;
      state.step = payload.step;
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.record = null;
      state.id = null;
      state.projectID = null;
      state.projectCreate = null;
      state.projectRelease = null;
      state.projectImplementation = null;
      state.projectReport = null;
      state.projectCompletion = null;
      state.projectFunding = null;
      state.projectGantt = null;
      state.projectTeam = null;
      state.status = null;
      state.step = null;
      state.findLoading = false;
    },

    PROJECT_CREATE_UPDATE_SUCCESS(state, payload) {
      state.projectCreate = payload || null;
      state.step = projectStepEnum.create;
    },

    PROJECT_RELEASE_UPDATE_SUCCESS(state, payload) {
      state.projectRelease = payload || null;
      state.step = projectStepEnum.release;
    },

    PROJECT_FUNDING_UPDATE_SUCCESS(state, payload) {
      state.projectFunding = payload || null;
      state.step = projectStepEnum.funding;
    },

    PROJECT_GANTT_UPDATE_SUCCESS(state, payload) {
      state.projectGantt = payload || null;
      state.step = projectStepEnum.gantt;
    },

    PROJECT_IMPLEMENTATION_UPDATE_SUCCESS(state, payload) {
      state.projectImplementation = payload || null;
      state.step = projectStepEnum.implementation;
    },

    PROJECT_REPORTS_UPDATE_SUCCESS(state, payload) {
      state.projectReport = payload || null;
      state.step = projectStepEnum.report;
    },

    PROJECT_COMPLETION_UPDATE_SUCCESS(state, payload) {
      state.projectCompletion = payload || null;
      state.step = projectStepEnum.completion;
    },

    PROJECT_STEP_UPDATE_SUCCESS(state, payload) {
      state.step = payload;
    },

    CLOSE_STARTED(state) {
      state.record = null;
      state.status = null;
      state.saveLoading = true;
    },

    CLOSE_SUCCESS(state, payload) {
      state.record = payload;
      state.status = payload.status;
      state.saveLoading = false;
    },

    CLOSE_ERROR(state) {
      state.saveLoading = false;
    },

  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doSetProjectCreateId({ commit }, id) {
      commit('PROJECT_CREATE_UPDATE_SUCCESS', id)
    },

    async doSetProjectReleaseId({ commit }, id) {
      commit('PROJECT_RELEASE_UPDATE_SUCCESS', id)
    },

    async doSetProjectFundingId({ commit }, id) {
      commit('PROJECT_FUNDING_UPDATE_SUCCESS', id)
    },

    async doSetProjectGanttId({ commit }, id) {
      commit('PROJECT_GANTT_UPDATE_SUCCESS', id)
    },

    async doSetProjectImplementationId({ commit }, id) {
      commit('PROJECT_IMPLEMENTATION_UPDATE_SUCCESS', id)
    },

    async doSetProjectReportId({ commit }, id) {
      commit('PROJECT_REPORTS_UPDATE_SUCCESS', id)
    },

    async doSetProjectCompletionId({ commit }, id) {
      commit('PROJECT_COMPLETION_UPDATE_SUCCESS', id)
    },

    async doUpdateProjectStep({ commit, dispatch }, payload) {
      commit('PROJECT_STEP_UPDATE_SUCCESS', payload)
      return dispatch('doUpdate');
    },

    async doReload({ commit, getters },) {
      try {
        const id = getters.projectId;
        commit('RELOAD_STARTED');
        const record = await ProjectService.find(id);
        commit('RELOAD_SUCCESS', record);
        return record;
      } catch (error) {
        Errors.handle(error);
        commit('RELOAD_ERROR');
      }
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await ProjectService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
      }
    },

    async doCreate({ commit }) {
      try {
        commit('CREATE_STARTED');
        const record = await ProjectService.create();
        commit('CREATE_SUCCESS', record);
        Message.success(i18n('entities.project.create.success'));
        routerAsync().push(`/project/${record.id}/edit`);
        return record;
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit, getters },) {
      try {
        const id = getters.projectId;
        const values = getters.project;
        commit('UPDATE_STARTED');
        const record = await ProjectService.update(id, values);
        commit('UPDATE_SUCCESS', record);
        Message.success(i18n('entities.project.update.success'));
        //routerAsync().push('/project');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doClose({ commit }, { id, values }) {
      try {
        commit('CLOSE_STARTED');
        const record = await ProjectService.close(id, values);
        commit('CLOSE_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('CLOSE_ERROR');
      }
    },
  },
};
