import Roles from '@/security/roles';
import Storage from '@/security/storage';

const roles = Roles.values;
const storage = Storage.values;

//TODO
//Make sure that permission on the frontend and backend are same
//Review all permissions

class Permissions {
  static get values() {
    return {
      iamCreate: {
        id: 'iamCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      iamNavigate: {
        id: 'iamNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      iamEdit: {
        id: 'iamEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
        allowedStorage: [storage.userProfileAvatar],
      },
      iamDestroy: {
        id: 'iamDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
        allowedStorage: [storage.userProfileAvatar],
      },
      iamImport: {
        id: 'iamImport',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      iamRead: {
        id: 'iamRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.deanship,
          roles.chancellor,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.readAdmin,
        ],
      },
      iamUserAutocomplete: {
        id: 'iamUserAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.deanship,
          roles.chancellor,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.readAdmin,
        ],
      },

      auditLogNavigate: {
        id: 'auditLogNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },

      settingsNavigate: {
        id: 'settingsNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },

      changeOwnPassword: {
        id: 'changeOwnPassword',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.deanship,
          roles.chancellor,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.readAdmin,
        ],
      },

      projectNavigate: {
        id: 'projectNavigate',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.deanship,
          roles.chancellor,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.readAdmin,
        ],
      },
      projectCreate: {
        id: 'projectCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      projectEdit: {
        id: 'projectEdit',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.deanship,
          roles.chancellor,
          roles.legalDepartment,
          roles.hrDepartment,
        ],
      },
      projectDestroy: {
        id: 'projectDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      projectRead: {
        id: 'projectRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.deanship,
          roles.chancellor,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.readAdmin,
        ],
      },

      projectCreateCreate: {
        id: 'projectCreateCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [
          storage.projectCreateTimeSheet,
          storage.projectCreateCostsPlan,
          storage.projectCreateDescriptionThirdPartyFunding,
          storage.projectCreateFinancialGuideline,
        ],
      },
      projectCreateEdit: {
        id: 'projectCreateEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [
          storage.projectCreateTimeSheet,
          storage.projectCreateCostsPlan,
          storage.projectCreateDescriptionThirdPartyFunding,
          storage.projectCreateFinancialGuideline,
        ],
      },
      projectCreateRead: {
        id: 'projectCreateRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.deanship,
          roles.hrDepartment,
          roles.readAdmin,
        ],
      },
      projectNote: {
        id: 'projectNote',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.deanship,
          roles.chancellor,
          roles.legalDepartment,
          roles.hrDepartment,
        ],
      },
      projectCreateFileFields: {
        id: 'projectCreateFileFields',
        type: 'files',
        fields: {
          timeSheet: {
            id: 'timeSheet',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.deanship,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.deanship,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin],
          },
          costsPlan: {
            id: 'costsPlan',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.deanship,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.deanship,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
          descriptionThirdPartyFunding: {
            id: 'descriptionThirdPartyFunding',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.deanship,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.deanship,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
          financialGuideline: {
            id: 'financialGuideline',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.deanship,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.deanship,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
        },
      },

      projectReleaseCreate: {
        id: 'projectReleaseCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [storage.projectReleaseSignedDocument],
      },
      projectReleaseEdit: {
        id: 'projectReleaseEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [storage.projectReleaseSignedDocument],
      },
      projectReleaseNotifyStep: {
        id: 'projectReleaseNotifyStep',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      projectReleaseRead: {
        id: 'projectReleaseRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.hrDepartment,
          roles.deanship,
          roles.readAdmin,
        ],
      },
      projectReleaseFileFields: {
        id: 'projectReleaseFileFields',
        type: 'files',
        fields: {
          signedDocument: {
            id: 'signedDocument',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
        },
      },

      projectReleaseRequestNavigate: {
        id: 'projectReleaseRequestNavigate',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.deanship,
          roles.readAdmin,
        ],
      },
      projectReleaseRequestCreate: {
        id: 'projectReleaseRequestCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      projectReleaseRequestEdit: {
        id: 'projectReleaseRequestEdit',
        allowedRoles: [
          roles.itAdmin,
          roles.deanship,
          roles.vrAdmin,
          roles.projectManager,
          roles.hrDepartment,
        ],
      },
      projectReleaseRequestDestroy: {
        id: 'projectReleaseDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      projectReleaseRequestRead: {
        id: 'projectReleaseRequestRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.deanship,
          roles.projectManager,
          roles.projectTeam,
          roles.hrDepartment,
          roles.readAdmin,
        ],
      },
      projectReleaseRequestAutocomplete: {
        id: 'projectReleaseRequestAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.hrDepartment,
          roles.readAdmin,
        ],
      },

      projectImplementationNavigate: {
        id: 'projectImplementationNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      projectImplementationCreate: {
        id: 'projectImplementationCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [storage.projectImplementationEthicsCommitteeDecision],
      },
      projectImplementationEdit: {
        id: 'projectImplementationEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [storage.projectImplementationEthicsCommitteeDecision],
      },
      projectImplementationDestroy: {
        id: 'projectImplementationDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
        allowedStorage: [storage.projectImplementationEthicsCommitteeDecision],
      },
      projectImplementationRead: {
        id: 'projectImplementationRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.deanship,
          roles.readAdmin,
        ],
      },
      projectImplementationFileFields: {
        id: 'projectImplementationFileFields',
        type: 'files',
        fields: {
          ethicsCommitteeDecision: {
            id: 'ethicsCommitteeDecision',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
        },
      },

      projectImplementationAutocomplete: {
        id: 'projectImplementationAutocomplete',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },

      projectFundingNavigate: {
        id: 'projectFundingNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      projectFundingCreate: {
        id: 'projectFundingCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      projectFundingEdit: {
        id: 'projectFundingEdit',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.hrDepartment,
        ],
      },
      projectFundingAssignProjectNo: {
        id: 'projectFundingAssignProjectNo',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.hrDepartment],
      },
      projectFundingRead: {
        id: 'projectFundingRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.hrDepartment,
          roles.deanship,
          roles.readAdmin,
        ],
      },

      projectReportCreate: {
        id: 'projectReportCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [
          storage.projectReportReportFile,
          storage.projectReportInternalReportFile,
          storage.projectReportGrantorsExtensionApprovalFile,
        ],
      },
      projectReportEdit: {
        id: 'projectReportEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [
          storage.projectReportReportFile,
          storage.projectReportInternalReportFile,
          storage.projectReportGrantorsExtensionApprovalFile,
        ],
      },
      projectReportRead: {
        id: 'projectReportRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.hrDepartment,
          roles.deanship,
          roles.readAdmin,
        ],
      },
      projectReportFileFields: {
        id: 'projectReportFileFields',
        type: 'files',
        fields: {
          reportFile: {
            id: 'reportFile',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
          internalReportFile: {
            id: 'internalReportFile',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
          grantorsExtensionApprovalFile: {
            id: 'grantorsExtensionApprovalFile',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
        },
      },

      projectCompletionCreate: {
        id: 'projectCompletionCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [
          storage.projectCompletionFinalReportFile,
          storage.projectCompletionFinalProjectBudgetAndFunding,
        ],
      },
      projectCompletionEdit: {
        id: 'projectCompletionEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [
          storage.projectCompletionFinalReportFile,
          storage.projectCompletionFinalProjectBudgetAndFunding,
        ],
      },
      projectCompletionCloseProject: {
        id: 'projectCompletionCloseProject',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      projectCompletionRead: {
        id: 'projectCompletionRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.hrDepartment,
          roles.deanship,
          roles.readAdmin,
        ],
      },
      projectCompletionFileFields: {
        id: 'projectCompletionFileFields',
        type: 'files',
        fields: {
          finalReportFile: {
            id: 'finalReportFile',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
          projectBudgetAndFunding: {
            id: 'projectBudgetAndFunding',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
        },
      },

      facultyNavigate: {
        id: 'facultyNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      facultyCreate: {
        id: 'facultyCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      facultyEdit: {
        id: 'facultyEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      facultyDestroy: {
        id: 'facultyDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      facultyRead: {
        id: 'facultyRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.deanship,
          roles.readAdmin,
        ],
      },
      facultyAutocomplete: {
        id: 'facultyAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },

      locationNavigate: {
        id: 'locationNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      locationCreate: {
        id: 'locationCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      locationEdit: {
        id: 'locationEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      locationDestroy: {
        id: 'locationDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      locationRead: {
        id: 'locationRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.deanship,
          roles.readAdmin,
        ],
      },
      locationAutocomplete: {
        id: 'locationAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },

      centerInstitutionNavigate: {
        id: 'centerInstitutionNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      centerInstitutionCreate: {
        id: 'centerInstitutionCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      centerInstitutionEdit: {
        id: 'centerInstitutionEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      centerInstitutionDestroy: {
        id: 'centerInstitutionDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      centerInstitutionRead: {
        id: 'centerInstitutionRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.readAdmin,
        ],
      },
      centerInstitutionAutocomplete: {
        id: 'centerInstitutionAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },

      outpatientClinicNavigate: {
        id: 'outpatientClinicNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      outpatientClinicCreate: {
        id: 'outpatientClinicCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      outpatientClinicEdit: {
        id: 'outpatientClinicEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      outpatientClinicDestroy: {
        id: 'outpatientClinicDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      outpatientClinicRead: {
        id: 'outpatientClinicRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.readAdmin,
        ],
      },
      outpatientClinicAutocomplete: {
        id: 'outpatientClinicAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },

      subjectAreaNavigate: {
        id: 'subjectAreaNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      subjectAreaImport: {
        id: 'subjectAreaImport',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      subjectAreaCreate: {
        id: 'subjectAreaCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      subjectAreaEdit: {
        id: 'subjectAreaEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      subjectAreaDestroy: {
        id: 'subjectAreaDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      subjectAreaRead: {
        id: 'subjectAreaRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.deanship,
          roles.readAdmin,
        ],
      },
      subjectAreaAutocomplete: {
        id: 'subjectAreaAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },

      researchFocusNavigate: {
        id: 'researchFocusNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      researchFocusImport: {
        id: 'researchFocusImport',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      researchFocusCreate: {
        id: 'researchFocusCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      researchFocusEdit: {
        id: 'researchFocusEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      researchFocusDestroy: {
        id: 'researchFocusDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      researchFocusRead: {
        id: 'researchFocusRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.deanship,
          roles.readAdmin,
        ],
      },
      researchFocusAutocomplete: {
        id: 'researchFocusAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },

      fundingAgencyNavigate: {
        id: 'fundingAgencyNavigate',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.hrDepartment,
          roles.readAdmin,
        ],
      },
      fundingAgencyCreate: {
        id: 'fundingAgencyCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      fundingAgencyEdit: {
        id: 'fundingAgencyEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      fundingAgencyDestroy: {
        id: 'fundingAgencyDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      fundingAgencyRead: {
        id: 'fundingAgencyRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.readAdmin,
        ],
      },
      fundingAgencyAutocomplete: {
        id: 'fundingAgencyAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },

      cooperationPartnerNavigate: {
        id: 'cooperationPartnerNavigate',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.hrDepartment,
          roles.readAdmin,
        ],
      },
      cooperationPartnerCreate: {
        id: 'cooperationPartnerCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      cooperationPartnerEdit: {
        id: 'cooperationPartnerEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      cooperationPartnerDestroy: {
        id: 'cooperationPartnerDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      cooperationPartnerRead: {
        id: 'cooperationPartnerRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.readAdmin,
        ],
      },
      cooperationPartnerAutocomplete: {
        id: 'cooperationPartnerAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },

      //TODO should HR be allowed to navigate
      grantNavigate: {
        id: 'grantNavigate',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.hrDepartment,
          roles.readAdmin,
        ],
      },

      grantCreate: {
        id: 'grantCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [storage.grantDecisionFile],
      },
      grantEdit: {
        id: 'grantEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [storage.grantDecisionFile],
      },
      grantDestroy: {
        id: 'grantDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
        allowedStorage: [storage.grantDecisionFile],
      },
      grantRead: {
        id: 'grantRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.readAdmin,
        ],
      },
      grantFileFields: {
        id: 'grantFileFields',
        type: 'files',
        fields: {
          decisionFile: {
            id: 'decisionFile',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
        },
      },

      grantAutocomplete: {
        id: 'grantAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },

      viceDeanResearchNavigate: {
        id: 'viceDeanResearchNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      viceDeanResearchCreate: {
        id: 'viceDeanResearchCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      viceDeanResearchEdit: {
        id: 'viceDeanResearchEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      viceDeanResearchDestroy: {
        id: 'viceDeanResearchDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      viceDeanResearchRead: {
        id: 'viceDeanResearchRead',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      viceDeanResearchAutocomplete: {
        id: 'viceDeanResearchAutocomplete',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },

      chancellorNavigate: {
        id: 'chancellorNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      chancellorCreate: {
        id: 'chancellorCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      chancellorEdit: {
        id: 'chancellorEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      chancellorDestroy: {
        id: 'chancellorDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      chancellorRead: {
        id: 'chancellorRead',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      chancellorAutocomplete: {
        id: 'chancellorAutocomplete',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },

      legalDepartmentNavigate: {
        id: 'legalDepartmentNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      legalDepartmentCreate: {
        id: 'legalDepartmentCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      legalDepartmentEdit: {
        id: 'legalDepartmentEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      legalDepartmentDestroy: {
        id: 'legalDepartmentDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      legalDepartmentRead: {
        id: 'legalDepartmentRead',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      legalDepartmentAutocomplete: {
        id: 'legalDepartmentAutocomplete',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },

      humanResourceNavigate: {
        id: 'humanResourceNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      humanResourceCreate: {
        id: 'humanResourceCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      humanResourceEdit: {
        id: 'humanResourceEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      humanResourceDestroy: {
        id: 'humanResourceDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      humanResourceRead: {
        id: 'humanResourceRead',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      humanResourceAutocomplete: {
        id: 'humanResourceAutocomplete',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },

      teamMemberNavigate: {
        id: 'teamMemberNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      teamMemberCreate: {
        id: 'teamMemberCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      teamMemberEdit: {
        id: 'teamMemberEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      teamMemberDestroy: {
        id: 'teamMemberDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      teamMemberRead: {
        id: 'teamMemberRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.readAdmin,
        ],
      },
      teamMemberAutocomplete: {
        id: 'teamMemberAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },

      projectTeamNavigate: {
        id: 'projectTeamNavigate',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },
      projectTeamCreate: {
        id: 'projectTeamCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      projectTeamEdit: {
        id: 'projectTeamEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      projectTeamDestroy: {
        id: 'projectTeamDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      projectTeamRead: {
        id: 'projectTeamRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.readAdmin,
        ],
      },
      projectTeamAutocomplete: {
        id: 'projectTeamAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },

      projectManagerNavigate: {
        id: 'projectManagerNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      projectManagerCreate: {
        id: 'projectManagerCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      projectManagerEdit: {
        id: 'projectManagerEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      projectManagerDestroy: {
        id: 'projectManagerDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
      },
      projectManagerRead: {
        id: 'projectManagerRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.readAdmin,
        ],
      },
      projectManagerAutocomplete: {
        id: 'projectManagerAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },

      cooperationPartnerContractNavigate: {
        id: 'cooperationPartnerContractNavigate',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.hrDepartment,
          roles.readAdmin,
        ],
      },
      cooperationPartnerContractCreate: {
        id: 'cooperationPartnerContractCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [
          storage.cooperationPartnerContractContractDraft,
          storage.cooperationPartnerContractContract,
        ],
      },
      cooperationPartnerContractEdit: {
        id: 'cooperationPartnerContractEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [
          storage.cooperationPartnerContractContractDraft,
          storage.cooperationPartnerContractContract,
        ],
      },
      cooperationPartnerContractDestroy: {
        id: 'cooperationPartnerContractDestroy',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.hrDepartment,
        ],
        allowedStorage: [
          storage.cooperationPartnerContractContractDraft,
          storage.cooperationPartnerContractContract,
        ],
      },
      cooperationPartnerContractRelease: {
        id: 'cooperationPartnerContractRelease',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.legalDepartment,
          roles.hrDepartment,
        ],
        allowedStorage: [
          storage.cooperationPartnerContractContractDraft,
          storage.cooperationPartnerContractContract,
        ],
      },
      cooperationPartnerContractRead: {
        id: 'cooperationPartnerContractRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.readAdmin,
        ],
      },
      cooperationPartnerContractFileFields: {
        id: 'cooperationPartnerContractFileFields',
        type: 'files',
        fields: {
          contractDraft: {
            id: 'contractDraft',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
          contract: {
            id: 'contract',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
        },
      },
      cooperationPartnerContractAutocomplete: {
        id: 'cooperationPartnerContractAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },

      employmentContractNavigate: {
        id: 'employmentContractNavigate',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.hrDepartment,
          roles.readAdmin,
        ],
      },
      employmentContractCreate: {
        id: 'employmentContractCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [
          storage.employmentContractContractDraft,
          storage.employmentContractContract,
        ],
      },
      employmentContractEdit: {
        id: 'employmentContractEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [
          storage.employmentContractContractDraft,
          storage.employmentContractContract,
        ],
      },
      employmentContractDestroy: {
        id: 'employmentContractDestroy',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.hrDepartment,
        ],
        allowedStorage: [
          storage.employmentContractContractDraft,
          storage.employmentContractContract,
        ],
      },
      employmentContractRelease: {
        id: 'employmentContractRelease',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.hrDepartment],
        allowedStorage: [
          storage.employmentContractContractDraft,
          storage.employmentContractContract,
        ],
      },
      employmentContractRead: {
        id: 'employmentContractRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.readAdmin,
        ],
      },
      employmentContractFileFields: {
        id: 'employmentContractFileFields',
        type: 'files',
        fields: {
          contractDraft: {
            id: 'contractDraft',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
          contract: {
            id: 'contract',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
        },
      },
      employmentContractAutocomplete: {
        id: 'employmentContractAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },

      projectGanttCreate: {
        id: 'projectGanttCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [storage.projectGanttGanttChart],
      },
      projectGanttEdit: {
        id: 'projectGanttEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
        allowedStorage: [storage.projectGanttGanttChart],
      },
      projectGanttRead: {
        id: 'projectGanttRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.readAdmin,
        ],
      },
      projectGanttFileFields: {
        id: 'projectGanttFileFields',
        type: 'files',
        fields: {
          ganttChart: {
            id: 'ganttChart',
            upload: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.legalDepartment,
              roles.hrDepartment,
            ],
            remove: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
          },
        },
      },
      projectGanttNavigate: {
        id: 'projectGanttNavigate',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.readAdmin,
        ],
      },

      workPackageNavigate: {
        id: 'workPackageNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      workPackageCreate: {
        id: 'workPackageCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      workPackageEdit: {
        id: 'workPackageEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      workPackageDestroy: {
        id: 'workPackageDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.projectManager],
      },
      workPackageRead: {
        id: 'workPackageRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.readAdmin,
        ],
      },
      workPackageAutocomplete: {
        id: 'workPackageAutocomplete',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.readAdmin,
        ],
      },

      templateNavigate: {
        id: 'templateNavigate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin, roles.readAdmin],
      },
      templateCreate: {
        id: 'templateCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
        allowedStorage: [storage.templateTemplate],
      },
      templateEdit: {
        id: 'templateEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
        allowedStorage: [storage.templateTemplate],
      },
      templateDestroy: {
        id: 'templateDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
        allowedStorage: [storage.templateTemplate],
      },
      templateRead: {
        id: 'templateRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.deanship,
          roles.readAdmin,
        ],
      },
      templateFileFields: {
        id: 'templateFileFields',
        type: 'files',
        fields: {
          template: {
            id: 'template',
            upload: [roles.itAdmin, roles.vrAdmin],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.projectTeam,
              roles.legalDepartment,
              roles.hrDepartment,
              roles.chancellor,
              roles.deanship,
            ],
            remove: [roles.itAdmin, roles.vrAdmin],
          },
        },
      },

      knowledgeBaseNavigate: {
        id: 'knowledgeBaseNavigate',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.deanship,
          roles.readAdmin,
        ],
      },
      knowledgeBaseCreate: {
        id: 'knowledgeBaseCreate',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
        allowedStorage: [storage.knowledgeBaseDocument],
      },
      knowledgeBaseEdit: {
        id: 'knowledgeBaseEdit',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
        allowedStorage: [storage.knowledgeBaseDocument],
      },
      knowledgeBaseDestroy: {
        id: 'knowledgeBaseDestroy',
        allowedRoles: [roles.itAdmin, roles.vrAdmin],
        allowedStorage: [storage.knowledgeBaseDocument],
      },
      knowledgeBaseRead: {
        id: 'knowledgeBaseRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.deanship,
          roles.readAdmin,
        ],
      },
      knowledgeBaseFileFields: {
        id: 'knowledgeBaseFileFields',
        type: 'files',
        fields: {
          document: {
            id: 'document',
            upload: [roles.itAdmin, roles.vrAdmin],
            download: [
              roles.itAdmin,
              roles.vrAdmin,
              roles.projectManager,
              roles.projectTeam,
              roles.legalDepartment,
              roles.hrDepartment,
              roles.chancellor,
              roles.deanship,
            ],
            remove: [roles.itAdmin, roles.vrAdmin],
          },
        },
      },

      reportRead: {
        id: 'reportRead',
        allowedRoles: [
          roles.itAdmin,
          roles.vrAdmin,
          roles.projectManager,
          roles.projectTeam,
          roles.legalDepartment,
          roles.hrDepartment,
          roles.chancellor,
          roles.deanship,
          roles.readAdmin,
        ],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }

  static getFilePermissions(model, fieldName) {
    return this.values[model].fields[fieldName];
  }
}

export default Permissions;
