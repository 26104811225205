import { ProjectReleaseRequestService } from '@/modules/project-release-request/project-release-request-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
    releaseStatus: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    releaseStatus: (state) => state.releaseStatus,
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state, payload) {
      state.record = payload;
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state, payload) {
      state.record = payload;
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },

    GET_RELEASE_REQUEST_STATE_STARTED(state) {
      state.saveLoading = true;
    },

    GET_RELEASE_REQUEST_STATE_SUCCESS(state, status) {
      state.releaseStatus = status;
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');

        const record = await ProjectReleaseRequestService.find(id);

        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/project-release-request');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        const record = await ProjectReleaseRequestService.create(values);
        commit('CREATE_SUCCESS', record);
        Message.success(i18n('entities.projectReleaseRequest.create.success'));
        //routerAsync().push('/project-release-request');
        return record;
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');

        const record = await ProjectReleaseRequestService.update(id, values);

        commit('UPDATE_SUCCESS', record);
        Message.success(i18n('entities.projectReleaseRequest.update.success'));
        //routerAsync().push('/project-release-request');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    doSetReleaseRequest({ commit }) {
      try {
        commit('GET_RELEASE_REQUEST_STATE_STARTED');
        /*
                let status = null;
                let decisions = [];
                const requestState = this.state.record.projectReleaseRequests;
        
                if (!requestState === undefined || !requestState.length == 0) {
                  for (let index = 0; index < requestState.length; index++) {
                    decisions.push(requestState[index]);
                  }
        
                  if (decisions.includes("rejected")) {
                    
                  }
                }
        
        */
        commit('GET_RELEASE_REQUEST_STATE_SUCCESS', status);
        Message.success(i18n('entities.projectReleaseRequest.update.success'));
        //routerAsync().push('/project-release-request');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    }

  },
};
