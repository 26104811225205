import fundingAgencyListStore from '@/modules/funding-agency/funding-agency-list-store';
import fundingAgencyViewStore from '@/modules/funding-agency/funding-agency-view-store';
import fundingAgencyFormStore from '@/modules/funding-agency/funding-agency-form-store';
import fundingAgencyDestroyStore from '@/modules/funding-agency/funding-agency-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: fundingAgencyDestroyStore,
    form: fundingAgencyFormStore,
    list: fundingAgencyListStore,
    view: fundingAgencyViewStore,
  },
};
