import projectCompletionFormStore from '@/modules/project-completion/project-completion-form-store';
import projectCompletionViewStore from '@/modules/project-completion/project-completion-view-store'
export default {
  namespaced: true,

  modules: {
    form: projectCompletionFormStore,    
    view: projectCompletionViewStore,
  },
};
