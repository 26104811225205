import routes from '@/modules/project/project-routes';
import store from '@/modules/project/project-store';
import projectGuardMixin from '@/modules/project/guards/project-guard-mixin';


const mixins = [
  projectGuardMixin
];

export default {
  routes,
  store,
  mixins,
};
