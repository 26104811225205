import authAxios from '@/shared/axios/auth-axios';

export class ProjectReleaseRequestService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/project-release-request/${id}`,
      body,
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/project-release-request`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/project-release-request/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/project-release-request`, {
      params,
    });

    return response.data;
  }
}
