import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const OutpatientClinicListPage = () =>
  import('@/modules/outpatient-clinic/components/outpatient-clinic-list-page.vue');
const OutpatientClinicFormPage = () =>
  import('@/modules/outpatient-clinic/components/outpatient-clinic-form-page.vue');
const OutpatientClinicViewPage = () =>
  import('@/modules/outpatient-clinic/components/outpatient-clinic-view-page.vue');


export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'outpatientClinic',
        path: '/outpatient-clinic',
        component: OutpatientClinicListPage,
        meta: {
          auth: true,
          permission: Permissions.values.outpatientClinicNavigate,
        },
      },
      {
        name: 'outpatientClinicNew',
        path: '/outpatient-clinic/new',
        component: OutpatientClinicFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.outpatientClinicNavigate,
        },
      },
      {
        name: 'outpatientClinicEdit',
        path: '/outpatient-clinic/:id/edit',
        component: OutpatientClinicFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.outpatientClinicNavigate,
        },
        props: true,
      },
      {
        name: 'outpatientClinicView',
        path: '/outpatient-clinic/:id',
        component: OutpatientClinicViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.outpatientClinicNavigate,
        },
        props: true,
      },
    ],
  },
];
