import researchFocusListStore from '@/modules/research-focus/research-focus-list-store';
import researchFocusViewStore from '@/modules/research-focus/research-focus-view-store';
import researchFocusImporterStore from '@/modules/research-focus/research-focus-importer-store';
import researchFocusFormStore from '@/modules/research-focus/research-focus-form-store';
import researchFocusDestroyStore from '@/modules/research-focus/research-focus-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: researchFocusDestroyStore,
    form: researchFocusFormStore,
    list: researchFocusListStore,
    view: researchFocusViewStore,
    importer: researchFocusImporterStore,
  },
};
