import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const FacultyListPage = () =>
  import('@/modules/faculty/components/faculty-list-page.vue');
const FacultyFormPage = () =>
  import('@/modules/faculty/components/faculty-form-page.vue');
const FacultyViewPage = () =>
  import('@/modules/faculty/components/faculty-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'faculty',
        path: '/faculty',
        component: FacultyListPage,
        meta: {
          auth: true,
          permission: Permissions.values.facultyNavigate,
        },
      },
      {
        name: 'facultyNew',
        path: '/faculty/new',
        component: FacultyFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.facultyNavigate,
        },
      },  
      {
        name: 'facultyEdit',
        path: '/faculty/:id/edit',
        component: FacultyFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.facultyNavigate,
        },
        props: true,
      },
      {
        name: 'facultyView',
        path: '/faculty/:id',
        component: FacultyViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.facultyNavigate,
        },
        props: true,
      },
    ],
  },
];
