<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
    >
      <app-grant-form
        :modal="true"
        :record="record"
        :saveLoading="saveLoading"
        @cancel="doCancel"
        @submit="doSubmit"
      />
    </el-dialog>
  </div>
</template>

<script>
import GrantForm from '@/modules/grant/components/grant-form';
import Errors from '@/shared/error/errors';
import { GrantService } from '@/modules/grant/grant-service';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { mapGetters } from 'vuex';
import { i18n } from '@/i18n';


export default {
  name: 'app-grant-form-modal',

  props: ['visible', 'projectFundingId', 'record'],

  components: {
    [GrantForm.name]: GrantForm,
  },

  data() {
    return {
      /* record: null, */
      saveLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      pendingFiles: 'grant/form/pendingFiles',
    }),
    
    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    isEditing() {
      return !!this.record;
    },

    title() {
      return this.isEditing
        ? i18n('entities.grant.edit.title')
        : i18n('entities.grant.new.title');
    },
  },

  methods: {
    async doSubmit(payload) {
      try {
        let record;
        this.saveLoading = true;

        if (this.isEditing) {
          record = await GrantService.update(
            payload.id,
            payload.values,
          );
        } else {
          if (this.projectFundingId) {
            payload.values.projectFunding = this.projectFundingId;
          }

          record = await GrantService.create(payload.values);
        }

        this.$emit('success', record);
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.saveLoading = false;
      }
    },

    async doCancel() {
      //if there are any pending temp files delete them from the server
      if (this.pendingFiles.length > 0) {
        await FileUploader.removePendingFiles(
          this.pendingFiles,
        );
      }
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
</style>
