<template>
  <div>
    <el-table
      :border="true"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column type="selection" width="55"></el-table-column>

      <el-table-column
        :label="fields.user.label"
        :prop="fields.user.name"
      >
        <template slot-scope="scope">
          <app-list-item-relation-to-one
            :label="fields.user.label"
            :readPermission="fields.user.readPermission"
            :navigatePermission="fields.user.navigatePermission"
            :url="fields.user.viewUrl"
            :value="presenter(scope.row, 'user')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.status.label"
        :prop="fields.status.name"
        sortable="custom"
        width="150"
      >
        <template v-slot="scope">
          <el-tag
            :type="getStatusTagType(scope.row.status)"
            v-if="isStatusNull(scope.row.status)"
            >{{ presenter(scope.row, 'status') }}</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.updatedAt.label"
        :prop="fields.updatedAt.name"
        sortable="custom"
      >
        <template v-slot="scope">{{
          presenter(scope.row, 'updatedAt')
        }}</template>
      </el-table-column>

      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        align="center"
        width="210"
      >
        <template slot-scope="scope">
          <div class="table-actions">
            <el-button
              :disabled="isActive(scope.row.status)"
              @click="updateStatus(scope.row.id, scope.row.status)"
              plain
              size="mini"
              type="success"
            >
              <app-i18n code="common.enable"></app-i18n>
            </el-button>

            <el-button
              :disabled="!isActive(scope.row.status)"
              @click="updateStatus(scope.row.id, scope.row.status)"
              plain
              size="mini"
              type="danger"
            >
              <app-i18n code="common.disable"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper" v-if="showPagination">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { ProjectManagerModel } from '@/modules/project-manager/project-manager-model';
import { mapGetters, mapActions } from 'vuex';
import { ProjectManagerPermissions } from '@/modules/project-manager/project-manager-permissions';
import { i18n } from '@/i18n';

const { fields } = ProjectManagerModel;

export default {
  name: 'app-project-manager-list-table',

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'projectManager/list/rows',
      count: 'projectManager/list/count',
      loading: 'projectManager/list/loading',
      pagination: 'projectManager/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'projectManager/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new ProjectManagerPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new ProjectManagerPermissions(this.currentUser).destroy;
    },

    showPagination() {
      return this.count > 10 && this.isInProjectTeam;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'projectManager/list/doChangeSort',
      doChangePaginationCurrentPage:
        'projectManager/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'projectManager/list/doChangePaginationPageSize',
      doMountTable: 'projectManager/list/doMountTable',
      doDestroy: 'projectManager/destroy/doDestroy',
      doUpdateStatus: 'projectManager/list/doUpdateStatus',
    }),

    presenter(row, fieldName) {
      return ProjectManagerModel.presenter(row, fieldName);
    },

    isStatusNull(status) {
      return status === null ? false : true;
    },

    getStatusTagType(value) {
      if (value === 'active') {
        return 'success';
      }
      if (value === 'inactive') {
        return 'danger';
      }
    },

    isActive(value) {
      return value === 'active';
    },

    async updateStatus(id, status) {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        let newStatus = '';
        if (status === 'active') {
          newStatus = 'inactive';
        } else {
          newStatus = 'active';
        }
        const values = {
          status: newStatus,
        };

        return this.doUpdateStatus({ id, values });
      } catch (error) {
        // no
      }
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style>
</style>
