import projectFundingFormStore from '@/modules/project-funding/project-funding-form-store';
import projectFundingViewStore from '@/modules/project-funding/project-funding-view-store';

export default {
  namespaced: true,

  modules: {
    form: projectFundingFormStore,
    view: projectFundingViewStore,  

  },
};
