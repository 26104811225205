import projectCreateFormStore from '@/modules/project-create/project-create-form-store';
import projectCreateViewStore from '@/modules/project-create/project-create-view-store';
export default {
  namespaced: true,

  modules: {
    view: projectCreateViewStore,
    form: projectCreateFormStore,
  },
};
