
import authAxios from '@/shared/axios/auth-axios';

export class ProjectFundingService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/project-funding/${id}`,
      body,
    );

    return response.data;
  }

  static async updateStatus(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/project-funding/status/${id}`,
      body,
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/project-funding`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/project-funding/${id}`);
    return response.data;
  }

  static async requestAllocationOfProjectNumberByAccounting(projectId) {
    const response = await authAxios.put(`/project-funding/request-assignment-from-accounting/${projectId}`);
    return response.data;
  }
}
