import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class CooperationPartnerContractPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.cooperationPartnerContractRead,
    );
    this.cooperationPartnerContractAutocomplete = permissionChecker.match(
      Permissions.values.cooperationPartnerContractAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.cooperationPartnerContractCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.cooperationPartnerContractEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.cooperationPartnerContractDestroy,
    );
    this.release = permissionChecker.match(
      Permissions.values.cooperationPartnerContractRelease,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.cooperationPartnerContractNavigate,
    );
  }
}
