<template>
  <div>
    <div class="app-page-spinner" v-if="findLoading" v-loading="findLoading"></div>

    <app-project-manager-form
      :isEditing="isEditing"
      :record="record"
      :saveLoading="saveLoading"
      @submit="doSubmit"
      v-if="!findLoading"
      v-model="model"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectManagerForm from '@/modules/project-manager/components/project-manager-form';
//import { routerAsync } from '@/app-module';
import Errors from '@/shared/error/errors';

export default {
  name: 'app-project-manager-form-project-team-page',

  props: ['value'],

  components: {
    [ProjectManagerForm.name]: ProjectManagerForm,
  },

  computed: {
    ...mapGetters({
      record: 'projectManager/form/record',
      findLoading: 'projectManager/form/findLoading',
      saveLoading: 'projectManager/form/saveLoading',
    }),

    isEditing() {
      return !this.value.length === 0;
    },

    model: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('input', value);
      },
    },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.value.id);
    } else {
      await this.doNew();
    }
  },

  methods: {
    ...mapActions({
      doFind: 'projectManager/form/doFind',
      doNew: 'projectManager/form/doNew',
      doUpdate: 'projectManager/form/doUpdate',
      doCreate: 'projectManager/form/doCreate',
    }),
    /*
    doCancel() {
      routerAsync().push('/project-manager');
    },
    */

    async doSubmit(payload) {
      try {
        if (this.isEditing) {
          return this.doUpdate(payload);
        } else {
          this.saveLoading = true;
          const record = await this.doCreate(
            payload.values,
          );
          const retData = {
            id: record.id,
            label: record.user.fullName,
          };
          const arr = [retData];

          this.model.push(retData);

          this.$emit('success', arr);
        }
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.saveLoading = false;
      }
    },
  },
};
</script>

<style>
</style>
