import { CooperationPartnerService } from '@/modules/cooperation-partner/cooperation-partner-service';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import RelationToManyField from '@/shared/fields/relation-to-many-field';
import Permissions from '@/security/permissions';

export class CooperationPartnerField {
  static relationToOne(name, label, options) {
    return new RelationToOneField(
      name,
      label,
      '/cooperation-partner',
      Permissions.values.cooperationPartnerRead,
      Permissions.values.cooperationPartnerNavigate,
      CooperationPartnerService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        label = `${record.institutionName}`;

        if (record.partner === 'person') {
          label = `${record.firstName} ${record.lastName} <${record.emailAddress}>`;
        }

        return {
          id: record.id,
          label,
        };
      },
      options,
    );
  }

  static relationToMany(name, label, options) {
    return new RelationToManyField(
      name,
      label,
      '/cooperation-partner',
      Permissions.values.cooperationPartnerRead,
      Permissions.values.cooperationPartnerNavigate,
      CooperationPartnerService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        label = `${record.institutionName}`;

        if (record.partner === 'person') {
          label = `${record.firstName} ${record.lastName} <${record.emailAddress}>`;
        }
        return {
          id: record.id,
          label,
        };
      },
      options,
    );
  }
}
