import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const TeamMemberListPage = () =>
  import('@/modules/team-member/components/team-member-list-page.vue');
const TeamMemberFormPage = () =>
  import('@/modules/team-member/components/team-member-form-page.vue');
const TeamMemberViewPage = () =>
  import('@/modules/team-member/components/team-member-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'teamMember',
        path: '/team-member',
        component: TeamMemberListPage,
        meta: {
          auth: true,
          permission: Permissions.values.teamMemberNavigate,
        },
      },
      {
        name: 'teamMemberNew',
        path: '/team-member/new',
        component: TeamMemberFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.teamMemberNavigate,
        },
      },
      {
        name: 'teamMemberEdit',
        path: '/team-member/:id/edit',
        component: TeamMemberFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.teamMemberNavigate,
        },
        props: true,
      },
      {
        name: 'teamMemberView',
        path: '/team-member/:id',
        component: TeamMemberViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.teamMemberNavigate,
        },
        props: true,
      },
    ],
  },
];
