<template>
  <el-form-item :label="label" v-if="!isBlank">
    <el-col :lg="13" :md="16" :sm="24">
      <div :key="item.id" v-for="item of value">
        <router-link
          :to="urlWithId(item)"
          v-if="
            hasPermissionToNavigate &&
              hasPermissionToRead &&
              urlExists
          "
        >
          <strong>{{ display(item) }}</strong>
        </router-link>
        <strong
          v-if="hasPermissionToRead && !hasPermissionToNavigate"
          >{{ display(item) }}</strong
        >
        <strong v-if="!urlExists">{{ display(item) }}</strong>
      </div>
    </el-col>
  </el-form-item>
</template>

<script>
import { mapGetters } from 'vuex';
import PermissionChecker from '@/modules/iam/permission-checker';

export default {
  name: 'app-view-item-relation-to-many',

  props: [
    'label',
    'value',
    'url',
    'readPermission',
    'navigatePermission',
  ],

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

    hasPermissionToRead() {
      return new PermissionChecker(this.currentUser).match(
        this.readPermission,
      );
    },

    hasPermissionToNavigate() {
      return new PermissionChecker(this.currentUser).match(
        this.navigatePermission,
      );
    },

    isBlank() {
      return !this.value || !this.value.length;
    },

    urlExists() {
      return (
        this.url !== null && this.url !== undefined && this.url !== ''
      );
    },
  },

  methods: {
    urlWithId(item) {
      if (!item) {
        return null;
      }

      return `${this.url}/${item.id}`;
    },

    display(item) {
      if (!item) {
        return null;
      }

      return item.label;
    },
  },
};
</script>

<style></style>
