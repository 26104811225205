import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class WorkPackagePermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.workPackageRead,
    );
    this.workPackageAutocomplete = permissionChecker.match(
      Permissions.values.workPackageAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.workPackageCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.workPackageEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.workPackageDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.workPackageNavigate,
    );
  }
}
