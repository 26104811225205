import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class TeamMemberPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.teamMemberRead,
    );
    this.teamMemberAutocomplete = permissionChecker.match(
      Permissions.values.teamMemberAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.teamMemberCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.teamMemberEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.teamMemberDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.teamMemberNavigate,
    );
  }
}
