import AuthService from '@/modules/auth/auth-service';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import ProgressBar from '@/shared/progress-bar/progress-bar';
import { AuthToken } from '@/modules/auth/auth-token';
import Roles from '@/security/roles';
const roles = Roles.values;

export default {
  namespaced: true,

  state: {
    currentUser: null,
    loadingInit: true,
    loadingEmailConfirmation: false,
    loadingPasswordResetEmail: false,
    loadingRequestPasswordReset: false,
    loadingVerifyEmail: false,
    loadingPasswordReset: false,
    loadingPasswordChange: false,
    loadingInitialPasswordChange: false,
    loadingUpdateProfile: false,
    loading: false,
    redirectUrl: null,
  },

  getters: {
    currentUser: (state) => state.currentUser,
    currentUserEmail: (state, getters) =>
      getters.currentUser ? getters.currentUser.email : null,
    currentUserFullName: (state, getters) =>
      getters.currentUser ? getters.currentUser.fullName : '',

    signedIn: (state, getters) =>
      !!getters.currentUser && !!getters.currentUser.id,

    roles: (state, getters) =>
      getters.currentUser ? getters.currentUser.roles || [] : [],

    isAdmin: (state, getters) =>
      getters.currentUser.roles.includes(roles.itAdmin) ||
      getters.currentUser.roles.includes(roles.vrAdmin),

    emptyPermissions: (state, getters) =>
      !getters.roles || !getters.roles.length,

    loading: (state) => !!state.loading,

    redirectUrlSet: (state) => !!state.redirectUrl,

    redirectUrl: (state) => state.redirectUrl,

    loadingInit: (state) => !!state.loadingInit,

    loadingEmailConfirmation: (state) =>
      !!state.loadingEmailConfirmation,

    loadingPasswordResetEmail: (state) =>
      !!state.loadingPasswordResetEmail,

    loadingRequestPasswordReset: (state) =>
      !!state.loadingRequestPasswordReset,

    loadingPasswordReset: (state) => !!state.loadingPasswordReset,

    loadingPasswordChange: (state) =>
      Boolean(state.loadingPasswordChange),

    loadingInitialPasswordChange: (state) =>
      !!state.loadingPasswordReset,

    loadingVerifyEmail: (state) => !!state.loadingVerifyEmail,

    loadingUpdateProfile: (state) => !!state.loadingUpdateProfile,

    currentUserNameOrEmailPrefix: (state, getters) => {
      if (!getters.currentUser) {
        return null;
      }

      if (
        getters.currentUserFullName &&
        getters.currentUserFullName.length < 50
      ) {
        return getters.currentUserFullName;
      }

      if (getters.currentUser.firstName) {
        return getters.currentUser.firstName;
      }

      return getters.currentUser.email.split('@')[0];
    },

    currentUserAvatar: (state, getters) => {
      if (
        !getters.currentUser ||
        !getters.currentUser.avatars ||
        !getters.currentUser.avatars.length ||
        !getters.currentUser.avatars[0].downloadUrl
      ) {
        return null;
      }

      return getters.currentUser.avatars[0].downloadUrl;
    },
  },

  mutations: {
    CURRENT_USER_REFRESH_SUCCESS(state, payload) {
      state.currentUser = payload.currentUser || null;
    },

    AUTH_START(state) {
      state.loading = true;
    },

    AUTH_SUCCESS(state, payload) {
      state.currentUser = payload.currentUser || null;
      state.loading = false;
    },

    AUTH_ERROR(state) {
      state.currentUser = null;
      state.loading = false;
    },

    EMAIL_CONFIRMATION_START(state) {
      state.loadingEmailConfirmation = true;
    },

    EMAIL_CONFIRMATION_SUCCESS(state) {
      state.loadingEmailConfirmation = false;
    },

    EMAIL_CONFIRMATION_ERROR(state) {
      state.loadingEmailConfirmation = false;
    },

    EMAIL_VERIFY_START(state) {
      state.loadingVerifyEmail = true;
    },

    EMAIL_VERIFY_SUCCESS(state) {
      state.loadingVerifyEmail = false;
    },

    EMAIL_VERIFY_ERROR(state) {
      state.loadingVerifyEmail = false;
    },

    PASSWORD_CHANGE_START(state) {
      state.loadingPasswordChange = true;
    },

    PASSWORD_CHANGE_SUCCESS(state) {
      state.loadingPasswordChange = false;
    },

    PASSWORD_CHANGE_ERROR(state) {
      state.loadingPasswordChange = false;
    },

    PASSWORD_INITIAL_CHANGE_START(state) {
      state.loadingInitialPasswordChange = true;
    },

    PASSWORD_INITIAL_CHANGE_SUCCESS(state) {
      state.loadingInitialPasswordChange = false;
    },

    PASSWORD_INITIAL_CHANGE_ERROR(state) {
      state.loadingInitialPasswordChange = false;
    },

    PASSWORD_RESET_START(state) {
      state.loadingPasswordReset = true;
    },

    PASSWORD_RESET_SUCCESS(state) {
      state.loadingPasswordReset = false;
    },

    PASSWORD_RESET_ERROR(state) {
      state.loadingPasswordReset = false;
    },

    PASSWORD_RESET_EMAIL_START(state) {
      state.loadingPasswordResetEmail = true;
    },

    PASSWORD_RESET_EMAIL_SUCCESS(state) {
      state.loadingPasswordResetEmail = false;
    },

    PASSWORD_RESET_EMAIL_ERROR(state) {
      state.loadingPasswordResetEmail = false;
    },

    REQUEST_PASSWORD_RESET_START(state) {
      state.loadingRequestPasswordReset = true;
    },

    REQUEST_PASSWORD_RESET_SUCCESS(state) {
      state.loadingRequestPasswordReset = false;
    },

    REQUEST_PASSWORD_RESET_ERROR(state) {
      state.loadingRequestPasswordReset = false;
    },

    UPDATE_PROFILE_START(state) {
      state.loadingUpdateProfile = true;
    },

    UPDATE_PROFILE_SUCCESS(state) {
      state.loadingUpdateProfile = false;
    },

    UPDATE_PROFILE_ERROR(state) {
      state.loadingUpdateProfile = false;
    },

    AUTH_INIT_SUCCESS(state, payload) {
      state.currentUser = payload.currentUser || null;
      state.loadingInit = false;
    },

    AUTH_INIT_ERROR(state) {
      state.currentUser = null;
      state.loadingInit = false;
    },

    SET_REDIRECT_URL(state, payload) {
      state.redirectUrl = payload.url;
    },

    ADD_USER_STARTED(state) {
      state.saveLoading = true;
    },

    ADD_USER_SUCCESS(state, payload) {
      state.currentUser = payload.currentUser || null;
      state.saveLoading = false;
    },

    ADD_USER_ERROR(state) {
      state.currentUser = null;
      state.saveLoading = false;
    },

    USER_SIGNUP_STARTED(state) {
      state.saveLoading = true;
    },

    USER_SIGNUP_SUCCESS(state) {
      state.saveLoading = false;
    },

    USER_SIGNUP_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    async doInit({ commit, dispatch }) {
      try {
        const token = await AuthToken.get();
        let currentUser = null;

        if (token) {
          currentUser = await AuthService.fetchMe();
        }

        commit('AUTH_INIT_SUCCESS', { currentUser });
        ProgressBar.done();
      } catch (error) {
        console.error(error);
        commit('AUTH_INIT_ERROR');
        await dispatch('doSignOut');
        ProgressBar.done();
      }
    },

    async doWaitUntilInit({ getters }) {
      if (!getters.loadingInit) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        const waitUntilInitInterval = setInterval(() => {
          if (!getters.loadingInit) {
            clearInterval(waitUntilInitInterval);
            resolve();
          }
        }, 500);
      });
    },

    async doSendEmailConfirmation({ commit }) {
      try {
        commit('EMAIL_CONFIRMATION_START');

        await AuthService.sendEmailVerification();

        Message.success(i18n('auth.verificationEmailSuccess'));

        commit('EMAIL_CONFIRMATION_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('EMAIL_CONFIRMATION_ERROR');
      }
    },

    async doSetAuthRedirectUrl({ commit }, url) {
      try {
        commit('SET_REDIRECT_URL', { url });
      } catch (error) {
        Errors.handle(error);
      }
    },

    async doSendPasswordResetEmail({ commit }, email) {
      try {
        commit('PASSWORD_RESET_EMAIL_START');
        await AuthService.sendPasswordResetEmail(email);
        Message.success(i18n('auth.passwordResetEmailSuccess'));
        commit('PASSWORD_RESET_EMAIL_SUCCESS');
      } catch (error) {
        commit('PASSWORD_RESET_EMAIL_ERROR');
        Errors.handle(error);
      }
    },

    async doRegisterEmailAndPassword(
      { commit },
      { email, password },
    ) {
      try {
        commit('AUTH_START');

        const tokens = await AuthService.registerWithEmailAndPassword(
          email,
          password,
        );

        const { token, refreshToken } = tokens;

        AuthToken.setTokens(token, refreshToken, true);

        const currentUser = await AuthService.fetchMe();

        commit('AUTH_SUCCESS', {
          currentUser,
        });

        routerAsync().push('/');
      } catch (error) {
        await AuthService.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },

    async doSigninWithEmailAndPassword(
      { commit, getters },
      { email, password, rememberMe },
    ) {
      try {
        commit('AUTH_START');

        let currentUser = null;

        const tokens = await AuthService.signinWithEmailAndPassword(
          email,
          password,
          rememberMe,
        );
        const { token, refreshToken } = tokens;

        AuthToken.setTokens(token, refreshToken, rememberMe);
        currentUser = await AuthService.fetchMe();

        commit('AUTH_SUCCESS', {
          currentUser,
        });

        //here we check if user is accessing from the url directly, if yes we route after sign in, if not we route to home
        if (getters.redirectUrlSet) {
          routerAsync().push(getters.redirectUrl);
        } else {
          routerAsync().push('/');
        }
      } catch (error) {
        await AuthService.signout();
        commit('AUTH_ERROR');
        Errors.handle(error);
      }
    },

    async doSignOut({ commit }) {
      try {
        commit('AUTH_START');
        await AuthService.signout();

        commit('AUTH_SUCCESS', {
          currentUser: null,
        });

        routerAsync().push('/auth/signin');
      } catch (error) {
        commit('AUTH_ERROR');
        Errors.handle(error);
      }
    },

    async doRefreshCurrentUser({ commit }) {
      try {
        let currentUser = null;
        const token = await AuthToken.get();

        if (token) {
          currentUser = await AuthService.fetchMe();
        }

        commit('CURRENT_USER_REFRESH_SUCCESS', {
          currentUser,
        });
      } catch (error) {
        AuthService.signout();
        commit('CURRENT_USER_REFRESH_ERROR', error);
        Errors.handle(error);
      }
    },

    async doUpdateProfile({ commit, dispatch }, data) {
      try {
        commit('UPDATE_PROFILE_START');
        await AuthService.updateProfile(data);
        commit('UPDATE_PROFILE_SUCCESS');
        await dispatch('doRefreshCurrentUser');
        Message.success(i18n('auth.profile.success'));
        routerAsync().push('/');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_PROFILE_ERROR');
      }
    },

    async doVerifyEmail({ commit, dispatch }, token) {
      try {
        commit('EMAIL_VERIFY_START');
        await AuthService.verifyEmail(token);
        Message.success(i18n('auth.verifyEmail.success'));
        await dispatch('doRefreshCurrentUser');
        commit('EMAIL_VERIFY_SUCCESS');
        routerAsync().push('/');
      } catch (error) {
        Errors.handle(error);
        commit('EMAIL_VERIFY_ERROR');
        await dispatch('doSignOut');
      }
    },

    async doResetPassword({ commit, dispatch }, { token, password }) {
      try {
        commit('PASSWORD_RESET_START');
        await AuthService.passwordReset(token, password);
        Message.success(i18n('auth.passwordResetSuccess'));
        commit('PASSWORD_RESET_SUCCESS');
        routerAsync().push('/');
      } catch (error) {
        Errors.handle(error);
        commit('PASSWORD_RESET_ERROR');
        await dispatch('doSignOut');
      }
    },

    async doCreateUserAfterSignup({ commit }, values) {
      try {
        commit('USER_SIGNUP_STARTED');
        await AuthService.createUserAfterSignup(values);
        commit('USER_SIGNUP_SUCCESS');
        //Message.success(i18n('iam.doSignupSuccess'));
        routerAsync().push('/auth/registration-success');
      } catch (error) {
        Errors.handle(error);
        commit('USER_SIGNUP_ERROR');
      }
    },

    async doChangePassword(
      { commit, dispatch },
      { oldPassword, newPassword },
    ) {
      try {
        commit('PASSWORD_CHANGE_START');
        await AuthService.changePassword(oldPassword, newPassword);
        commit('PASSWORD_CHANGE_SUCCESS');
        await dispatch('doRefreshCurrentUser');
        Message.success(i18n('auth.passwordChange.success'));
        routerAsync().push('/');
      } catch (error) {
        Errors.handle(error);
        commit('PASSWORD_CHANGE_ERROR');
      }
    },

    async doInitialPasswordChange(
      { commit, dispatch },
      { password },
    ) {
      try {
        commit('PASSWORD_INITIAL_CHANGE_START');
        await AuthService.initialPasswordChange(password);
        commit('PASSWORD_INITIAL_CHANGE_SUCCESS');
        await dispatch('doRefreshCurrentUser');
        Message.success(i18n('auth.passwordChange.success'));
        routerAsync().push('/');
      } catch (error) {
        Errors.handle(error);
        commit('PASSWORD_INITIAL_CHANGE_ERROR');
      }
    },
  },
};
