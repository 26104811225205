import outpatientClinicListStore from '@/modules/outpatient-clinic/outpatient-clinic-list-store';
import outpatientClinicViewStore from '@/modules/outpatient-clinic/outpatient-clinic-view-store';
import outpatientClinicFormStore from '@/modules/outpatient-clinic/outpatient-clinic-form-store';
import outpatientClinicDestroyStore from '@/modules/outpatient-clinic/outpatient-clinic-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: outpatientClinicDestroyStore,
    form: outpatientClinicFormStore,
    list: outpatientClinicListStore,
    view: outpatientClinicViewStore,
    
  },
};
