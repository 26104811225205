import importerStore from '@/shared/importer/importer-store';
import { SubjectAreaService } from '@/modules/subject-area/subject-area-service';
import subjectAreaImporterFields from '@/modules/subject-area/subject-area-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  SubjectAreaService.import,
  subjectAreaImporterFields,
  i18n('entities.subjectArea.importer.fileName'),
  i18n('entities.subjectArea.importer.hint'),
);
