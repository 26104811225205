import templateListStore from '@/modules/template/template-list-store';
import templateFormStore from '@/modules/template/template-form-store';
import templateDestroyStore from '@/modules/template/template-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: templateDestroyStore,
    form: templateFormStore,
    list: templateListStore,
  },
};
