import teamMemberListStore from '@/modules/team-member/team-member-list-store';
import teamMemberViewStore from '@/modules/team-member/team-member-view-store';
import teamMemberFormStore from '@/modules/team-member/team-member-form-store';
import teamMemberDestroyStore from '@/modules/team-member/team-member-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: teamMemberDestroyStore,
    form: teamMemberFormStore,
    list: teamMemberListStore,
    view: teamMemberViewStore,
  },
};
