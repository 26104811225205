import authAxios from '@/shared/axios/auth-axios';

export class ProjectCreateService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/project-create/${id}`,
      body,
    );

    return response.data;
  }
  
  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/project-create`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/project-create/${id}`);
    return response.data;
  }

}
