import subjectAreaListStore from '@/modules/subject-area/subject-area-list-store';
import subjectAreaViewStore from '@/modules/subject-area/subject-area-view-store';
import subjectAreaImporterStore from '@/modules/subject-area/subject-area-importer-store';
import subjectAreaFormStore from '@/modules/subject-area/subject-area-form-store';
import subjectAreaDestroyStore from '@/modules/subject-area/subject-area-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: subjectAreaDestroyStore,
    form: subjectAreaFormStore,
    list: subjectAreaListStore,
    view: subjectAreaViewStore,
    importer: subjectAreaImporterStore,
  },
};
