import projectTeamListStore from '@/modules/project-team/project-team-list-store';
import projectTeamViewStore from '@/modules/project-team/project-team-view-store';
import projectTeamFormStore from '@/modules/project-team/project-team-form-store';

export default {
  namespaced: true,

  modules: {
    form: projectTeamFormStore,
    list: projectTeamListStore,
    view: projectTeamViewStore,    
  },
};
