<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
    >
      <app-work-package-form
        :modal="true"
        :record="record"
        :saveLoading="saveLoading"
        @cancel="doCancel"
        @submit="doSubmit"
      />
    </el-dialog>
  </div>
</template>

<script>
import WorkPackageForm from '@/modules/work-package/components/work-package-form';
import { WorkPackageService } from '@/modules/work-package/work-package-service';
import { i18n } from '@/i18n';
import Errors from '@/shared/error/errors';

export default {
  name: 'app-work-package-form-modal',

  props: ['visible', 'projectGanttId', 'record'],

  components: {
    [WorkPackageForm.name]: WorkPackageForm,
  },

  data() {
    return {
      //record: null,
      saveLoading: false,
    };
  },

  computed: {
    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    isEditing() {
      return !!this.record;
    },

    title() {
      return this.isEditing
        ? i18n('entities.workPackage.edit.title')
        : i18n('entities.workPackage.new.title');
    },
  },

  methods: {    
    async doSubmit(payload) {
      try {
        let record;
        this.saveLoading = true;

        if (this.isEditing) {
          record = await WorkPackageService.update(
            payload.id,
            payload.values,
          );
        } else {
          if (this.projectGanttId) {
            //set projectImplementationId so that is linked to cooperation contract
            payload.values.projectGantt = this.projectGanttId;
          }
          record = await WorkPackageService.create(
            payload.values,
          );
        }

        this.$emit('success', record);
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.saveLoading = false;
      }
    },


    doCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
</style>
