import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class ViceDeanResearchPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.viceDeanResearchRead,
    );
    this.viceDeanResearchAutocomplete = permissionChecker.match(
      Permissions.values.viceDeanResearchAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.viceDeanResearchCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.viceDeanResearchEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.viceDeanResearchDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.viceDeanResearchNavigate,
    );
  }
}
