import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class SubjectAreaPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.subjectAreaRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.subjectAreaImport,
    );
    this.subjectAreaAutocomplete = permissionChecker.match(
      Permissions.values.subjectAreaAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.subjectAreaCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.subjectAreaEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.subjectAreaDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.subjectAreaNavigate,
    );
  }
}
