<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
    >
      <app-subject-area-form
        :modal="true"
        :record="record"
        :saveLoading="saveLoading"
        @cancel="doCancel"
        @submit="doSubmit"
      />
    </el-dialog>
  </div>
</template>

<script>
import SubjectAreaForm from '@/modules/subject-area/components/subject-area-form';
import { SubjectAreaService } from '@/modules/subject-area/subject-area-service';
import { i18n } from '@/i18n';
import Errors from '@/shared/error/errors';

export default {
  name: 'app-subject-area-form-modal',

  props: ['visible', 'parentId'],

  components: {
    [SubjectAreaForm.name]: SubjectAreaForm,
  },

  data() {
    return {
      record: null,
      saveLoading: false,
    };
  },

  computed: {
    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    title() {
      return i18n('entities.subjectArea.new.title');
    },
  },

  methods: {
    async doSubmit(payload) {
      try {
        if (this.parentId) {
          payload.values.projectCreate = this.parentId;
        }

        this.saveLoading = true;
        const { id } = await SubjectAreaService.create(
          payload.values,
        );
        const record = await SubjectAreaService.find(id);
        this.$emit('success', record);
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.saveLoading = false;
      }
    },

    doCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
</style>
