import _get from 'lodash/get';
import moment from 'moment';
import { setLocale as setYupLocale } from 'yup';

let currentLanguageCode = null;

const languages = {
  en: {
    id: 'en',
    label: 'English',
    flag: '/images/flags/24/United-Kingdom.png',
    dictionary: null,
    elementUI: null,
  },
  de: {
    id: 'de',
    label: 'Deutsch',
    flag: '/images/flags/24/Germany.png',
    dictionary: null,
    elementUI: null,
  },
};

export async function init() {
  currentLanguageCode =
    localStorage.getItem('language') || 'en';
  setLanguageCode(currentLanguageCode);

  if (currentLanguageCode === 'en') {
    await initEn();
  }

  if (currentLanguageCode === 'de') {
    await initDe();
  }

  //localStorage.setItem('elementUiDateFormat', getLocaleFormatStringForElementUI());
  localStorage.setItem('elementUiDateFormat', "dd.MM.yyyy");
}

async function initEn() {
  const language = languages['en'];
  language.dictionary = (await import('@/i18n/en')).default;
  language.elementUI = (await import('element-ui/lib/locale/lang/en')).default;
  if (language.dictionary.validation) {
    setYupLocale(language.dictionary.validation);
  }
  return language;
}

async function initDe() {
  const language = languages['de'];
  const momentLocale = (await import('moment/locale/de')).default;
  language.elementUI = (await import('element-ui/lib/locale/lang/de')).default;
  language.dictionary = (await import('@/i18n/de')).default;
  moment.updateLocale('de', momentLocale);
  if (language.dictionary.validation) {
    setYupLocale(language.dictionary.validation);
  }
  return language;
}

function getLanguage() {
  return languages[getLanguageCode()];
}

// Until proper local for date time format is set we will not use this function
// hard codded value on line 38 will be used
/* 
function getLocaleFormatStringForElementUI() {
  var localeData = moment.localeData();
  var format = localeData.longDateFormat('L');
  const day = format.substring(0, 2).toLowerCase();
  const month = format.substring(3, 5);
  const year = format.substring(6, format.length).toLowerCase();
  const formatDate = day + "." + month + "." + year;
  return formatDate;
} */

function format(message, args) {
  if (!message) {
    return null;
  }

  try {
    return message.replace(/{(\d+)}/g, function (
      match,
      number,
    ) {
      return typeof args[number] != 'undefined'
        ? args[number]
        : match;
    });
  } catch (error) {
    console.error(message, error);
    throw error;
  }
}

export function getLanguages() {
  return Object.keys(languages).map((language) => {
    return languages[language];
  });
}

export function getElementUILanguage() {
  return getLanguage().elementUI;
}

export function getLanguageCode() {
  return currentLanguageCode;
}

export function setLanguageCode(arg) {
  if (!languages[arg]) {
    throw new Error(`Invalid language ${arg}.`);
  }
  localStorage.setItem('language', arg);

}

export function i18nExists(key) {
  if (!getLanguage()) {
    return false;
  }
  const message = _get(getLanguage().dictionary, key);
  return Boolean(message);
}

export function i18n(key, ...args) {
  if (!getLanguage()) {
    return key;
  }
  const message = _get(getLanguage().dictionary, key);
  if (!message) {
    return key;
  }
  return format(message, args);
}
