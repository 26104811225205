import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class LocationPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.locationRead,
    );
    this.locationAutocomplete = permissionChecker.match(
      Permissions.values.locationAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.locationCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.locationEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.locationDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.locationNavigate,
    );
  }
}
