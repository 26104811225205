import legalDepartmentListStore from '@/modules/legal-department/legal-department-list-store';
import legalDepartmentViewStore from '@/modules/legal-department/legal-department-view-store';
import legalDepartmentFormStore from '@/modules/legal-department/legal-department-form-store';
import legalDepartmentDestroyStore from '@/modules/legal-department/legal-department-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: legalDepartmentDestroyStore,
    form: legalDepartmentFormStore,
    list: legalDepartmentListStore,
    view: legalDepartmentViewStore,
    
  },
};
