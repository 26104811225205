import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const ProjectTeamListPage = () =>
  import('@/modules/project-team/components/project-team-list-page.vue');
const ProjectTeamFormPage = () =>
  import('@/modules/project-team/components/project-team-form-page.vue');
const ProjectTeamViewPage = () =>
  import('@/modules/project-team/components/project-team-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'projectTeam',
        path: '/project-team',
        component: ProjectTeamListPage,
        meta: {
          auth: true,
          permission: Permissions.values.projectTeamNavigate,
        },
      },
      {
        name: 'projectTeamNew',
        path: '/project-team/new',
        component: ProjectTeamFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.projectTeamNavigate,
        },
      },
      {
        name: 'projectTeamEdit',
        path: '/project-team/:id/edit',
        component: ProjectTeamFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.projectTeamNavigate,
        },
        props: true,
      },
      {
        name: 'projectTeamView',
        path: '/project-team/:id',
        component: ProjectTeamViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.projectTeamNavigate,
        },
        props: true,
      },
    ],
  },
];
