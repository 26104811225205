import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const CenterInstitutionListPage = () =>
  import('@/modules/center-institution/components/center-institution-list-page.vue');
const CenterInstitutionFormPage = () =>
  import('@/modules/center-institution/components/center-institution-form-page.vue');
const CenterInstitutionViewPage = () =>
  import('@/modules/center-institution/components/center-institution-view-page.vue');
export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'centerInstitution',
        path: '/center-institution',
        component: CenterInstitutionListPage,
        meta: {
          auth: true,
          permission: Permissions.values.centerInstitutionNavigate,
        },
      },
      {
        name: 'centerInstitutionNew',
        path: '/center-institution/new',
        component: CenterInstitutionFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.centerInstitutionNavigate,
        },
      },
      {
        name: 'centerInstitutionEdit',
        path: '/center-institution/:id/edit',
        component: CenterInstitutionFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.centerInstitutionNavigate,
        },
        props: true,
      },
      {
        name: 'centerInstitutionView',
        path: '/center-institution/:id',
        component: CenterInstitutionViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.centerInstitutionNavigate,
        },
        props: true,
      },
    ],
  },
];
