import employmentContractListStore from '@/modules/employment-contract/employment-contract-list-store';
import employmentContractFormStore from '@/modules/employment-contract/employment-contract-form-store';
import employmentContractDestroyStore from '@/modules/employment-contract/employment-contract-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: employmentContractDestroyStore,
    form: employmentContractFormStore,
    list: employmentContractListStore, 
  },
};
