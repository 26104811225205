import projectManagerListStore from '@/modules/project-manager/project-manager-list-store';
import projectManagerViewStore from '@/modules/project-manager/project-manager-view-store';
import projectManagerFormStore from '@/modules/project-manager/project-manager-form-store';
import projectManagerDestroyStore from '@/modules/project-manager/project-manager-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: projectManagerDestroyStore,
    form: projectManagerFormStore,
    list: projectManagerListStore,
    view: projectManagerViewStore,    
  },
};
