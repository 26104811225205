import authAxios from '@/shared/axios/auth-axios';

export class ProjectNoteService {
  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/project/note`, body);

    return response.data;
  }

  static async list(projectId) {
    const response = await authAxios.get(
      `/project/note/${projectId}`,
    );
    return response.data;
  }
}
