import { ProjectGanttService } from '@/modules/project-gantt/project-gantt-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
    formModel: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    status: (state, getters) => getters.record ? getters.record.status : null,
    formModel: (state) => state.formModel,
    pendingFiles: (state, getters) => {
      if (!getters.formModel)
        return [];

      return getters.formModel.ganttChart.filter(f => f.new);
    },
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
      state.formModel = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
      state.formModel = null;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
      state.formModel = null;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state, payload) {
      state.record = payload;
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state, payload) {
      state.record = payload;
      state.saveLoading = false;
      state.formModel = null;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },

    SET_FORM_MODEL(state, payload) {
      state.formModel = payload;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doSetFormModel({ commit }, payload) {
      commit('SET_FORM_MODEL', payload)
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await ProjectGanttService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        const record = await ProjectGanttService.create(values);
        commit('CREATE_SUCCESS', record);
        Message.success(i18n('entities.projectGantt.create.success'));
        return record.id;
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        const record = await ProjectGanttService.update(id, values);
        commit('UPDATE_SUCCESS', record);
        Message.success(i18n('entities.projectGantt.update.success'));
        return record;
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
