import { storeAsync } from '@/app-module';
import ProjectStepEnum from '@/shared/enums/projectStep';
import { i18n } from '@/i18n';
import Message from '@/shared/message/message';

const projectStepEnumIds = ProjectStepEnum.ids;

export default {
  async beforeRouteEnter(to, from, next) {
    if (!to.meta || !to.meta.auth || !to.meta.projectRoute) {
      next();
      return;
    }

    const store = storeAsync();
    const projectId = to.params.id;

    let projectStep = store.getters['project/form/step'];

    if (!projectStep) {
      await store.dispatch('project/form/doFind', projectId);
      projectStep = store.getters['project/form/step'];
    }


    if (projectStep) {
      let step = to.name;
      step = projectStepEnumIds[step];
      const projectStepId = projectStepEnumIds[projectStep];

      if (step <= projectStepId) {
        next();
        return;
      }

    }

    Message.error(
      i18n('errors.projectNavigateStep')
      );

    next({ path: '/' });
    return;

  },
};
