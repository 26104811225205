import workPackageListStore from '@/modules/work-package/work-package-list-store';
import workPackageFormStore from '@/modules/work-package/work-package-form-store';
import workPackageDestroyStore from '@/modules/work-package/work-package-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: workPackageDestroyStore,
    form: workPackageFormStore,
    list: workPackageListStore,
  },
};
