import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const TemplateListPage = () =>
  import('@/modules/template/components/template-list-page.vue');
const TemplateFormPage = () =>
  import('@/modules/template/components/template-form-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'template',
        path: '/template',
        component: TemplateListPage,
        meta: {
          auth: true,
          permission: Permissions.values.templateView,
        },
      },
      {
        name: 'templateNew',
        path: '/template/new',
        component: TemplateFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.templateCreate,
        },
      },   
      {
        name: 'templateEdit',
        path: '/template/:id/edit',
        component: TemplateFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.templateEdit,
        },
        props: true,
      },      
    ],
  },
];
