import { AuthToken } from '@/modules/auth/auth-token';
import Axios from 'axios';
import Qs from 'qs';
import moment from 'moment';
import config from '@/config';
import { getLanguageCode } from '@/i18n';
/* import { router } from '@/app-module'; */

const authAxios = Axios.create({
  baseURL: config.backendUrl,
  paramsSerializer: function (params) {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      filter: (prefix, value) => {
        if (
          moment.isMoment(value) ||
          value instanceof Date
        ) {
          return value.toISOString();
        }

        return value;
      },
    });
  },
});

authAxios.interceptors.request.use(
  async function (options) {
    const token = await AuthToken.get();

    if (token) {
      options.headers['Authorization'] = `Bearer ${token}`;
    }

    options.headers['Accept-Language'] = getLanguageCode();

    return options;
  },
  function (error) {
    console.log('Request error: ', error);
    return Promise.reject(error);
  },
);

authAxios.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;

  if (error.response.status === 401 && originalRequest.url ===
    '/auth/refresh-token') {
    /* window.location.href = `${config.backendUrl}/auth/signin`; */
    window.location.href = `/auth/signin`;
    return Promise.reject(error);
  }

  if (error.response.status === 401 && !originalRequest._retry) {

    originalRequest._retry = true;
    const refreshToken = await AuthToken.getRefreshToken();
    return authAxios.post('/auth/refresh-token',
      {
        "refreshToken": refreshToken
      })
      .then(res => {
        if (res.status === 200) {
          const token = res.data;
          AuthToken.setAccessToken(token);
          authAxios.defaults.headers.common['Authorization'] = 'Bearer ' + AuthToken.get();
          return authAxios(originalRequest);
        }
      })
  }
  return Promise.reject(error);
});

export default authAxios;
