import projectReleaseFormStore from '@/modules/project-release/project-release-form-store';
import projectReleaseViewStore from '@/modules/project-release/project-release-view-store';
export default {
  namespaced: true,

  modules: {
    form: projectReleaseFormStore,
    view: projectReleaseViewStore
  },
};
