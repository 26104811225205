<template>
  <div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
    >
     
      <el-form-item
        :label="fields.cooperationPartner.label"
        :prop="fields.cooperationPartner.name"
        :required="fields.cooperationPartner.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <app-cooperation-partner-autocomplete-input
            :fetchFn="fields.cooperationPartner.fetchFn"
            :mapperFn="fields.cooperationPartner.mapperFn"
            :parentId="projectCreateId"
            :showCreate="!modal"
            mode="single"
            v-model="model[fields.cooperationPartner.name]"
          ></app-cooperation-partner-autocomplete-input>
        </el-col>
      </el-form-item>

      <el-form-item
        :disabled="isReleased"
        :label="fields.contractDraft.label"
        :prop="fields.contractDraft.name"
        :required="fields.contractDraft.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <app-file-upload
            :disabled="isReleased"
            :max="fields.contractDraft.max"
            :storage="fields.contractDraft.storage"
            :formats="fields.contractDraft.formats"
            :permissions="fields.contractDraft.permissions"
            v-model="model[fields.contractDraft.name]"
          ></app-file-upload>
        </el-col>
      </el-form-item>

      <el-form-item
        v-if="isReleased"
        :label="fields.contract.label"
        :prop="fields.contract.name"
        :required="fields.contract.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <app-file-upload
            :max="fields.contract.max"
            :storage="fields.contract.storage"
            :formats="fields.contract.formats"
            :permissions="fields.contract.permissions"
            v-model="model[fields.contract.name]"
          ></app-file-upload>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.contractType.label"
        :prop="fields.contractType.name"
        :required="fields.contractType.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <el-input
            ref="focus"
            v-model="model[fields.contractType.name]"
          />
        </el-col>
      </el-form-item>

      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
          >
            <app-i18n code="common.save"></app-i18n>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
          >
            <app-i18n code="common.cancel"></app-i18n>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { CooperationPartnerContractModel } from '@/modules/cooperation-partner-contract/cooperation-partner-contract-model';

const { fields } = CooperationPartnerContractModel;
const formSchema = new FormSchema([
  fields.id,
  fields.cooperationPartner,
  fields.projectImplementation,
  fields.contractDraft,
  fields.contract,
  fields.contractType,
  fields.status,
  fields.date,
  fields.updatedAt,
]);

export default {
  name: 'app-cooperation-partner-contract-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      projectCreateId: 'project/form/projectCreateId',
    }),

    isReleased() {
      return this.model.status === 'released';
    },

    fields() {
      return fields;
    },
  },

  watch: {
    record: function () {
      this.doUpdateModel();
    },
    model: {
      deep: true,
      handler: function () {
        this.$store.dispatch(
          'cooperationPartnerContract/form/doSetFormModel',
          this.model,
        );
      },
    },
  },

  methods: {
    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },
  },
};
</script>

<style>
</style>
