import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const ProjectManagerListPage = () =>
  import('@/modules/project-manager/components/project-manager-list-page.vue');
const ProjectManagerFormPage = () =>
  import('@/modules/project-manager/components/project-manager-form-page.vue');
const ProjectManagerViewPage = () =>
  import('@/modules/project-manager/components/project-manager-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'projectManager',
        path: '/project-manager',
        component: ProjectManagerListPage,
        meta: {
          auth: true,
          permission: Permissions.values.projectManagerView,
        },
      },
      {
        name: 'projectManagerNew',
        path: '/project-manager/new',
        component: ProjectManagerFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.projectManagerCreate,
        },
      },
      {
        name: 'projectManagerEdit',
        path: '/project-manager/:id/edit',
        component: ProjectManagerFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.projectManagerEdit,
        },
        props: true,
      },
      {
        name: 'projectManagerView',
        path: '/project-manager/:id',
        component: ProjectManagerViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.projectManagerView,
        },
        props: true,
      },
    ],
  },
];
