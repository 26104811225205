import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';

function label(name) {
  return i18n(`entities.cooperationPartner.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(
    `entities.cooperationPartner.enumerators.${name}.${value}`,
  );
}

const fields = {
  id: new IdField('id', label('id')),
  lastName: new StringField('lastName', label('lastName'), {
    required: true,
    max: 255,
  }),
  firstName: new StringField('firstName', label('firstName'), {
    required: true,
    max: 255,
  }),
  title: new StringField('title', label('title'), {
    max: 255,
  }),
  affiliation: new StringField('affiliation', label('affiliation'), {
    max: 255,
  }),
  emailAddress: new StringField(
    'emailAddress',
    label('emailAddress'),
    {
      required: true,
      matches: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      max: 254,
    },
  ),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), {
    required: false,
    matches: /^[0-9]/,
    max: 24,
  }),
  institutionName: new StringField(
    'institutionName',
    label('institutionName'),
    {
      required: true,
      max: 255,
    },
  ),
  address: new StringField('address', label('address'), {
    required: true,
    max: 255,
  }),
  contactPerson: new StringField(
    'contactPerson',
    label('contactPerson'),
    {
      required: true,
      max: 255,
    },
  ),
  webAddress: new StringField('webAddress', label('webAddress'), {
    max: 255,
  }),
  partner: new EnumeratorField(
    'partner',
    label('partner'),
    [
      { id: 'person', label: enumeratorLabel('partner', 'person') },
      {
        id: 'institution',
        label: enumeratorLabel('partner', 'institution'),
      },
    ],
    {},
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class CooperationPartnerModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
