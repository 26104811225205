import authAxios from '@/shared/axios/auth-axios';

export class WorkPackageService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/work-package/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/work-package`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/work-package`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/work-package/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/work-package`, {
      params,
    });

    return response.data;
  }

static async listAutocomplete(limit, query, parentId) {
    const params = {
      limit,
      query,
      parentId,
    };

    const response = await authAxios.get(
      `/work-package/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }
}
