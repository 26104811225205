import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const ProjectListPage = () =>
  import('@/modules/project/components/project-list-page.vue');
const ProjectViewPage = () =>
  import('@/modules/project/components/project-view-page.vue');
const ProjectFormPage = () =>
  import('@/modules/project/components/project-form-page.vue');
const ProjectCreatePage = () =>
  import('@/modules/project-create/components/project-create-form-project-page.vue');
const ProjectCreateViewPage = () =>
  import('@/modules/project-create/components/project-create-view-page.vue');
const ProjectReleasePage = () =>
  import('@/modules/project-release/components/project-release-form-project-page.vue');
const ProjectReleaseViewPage = () =>
  import('@/modules/project-release/components/project-release-view-page.vue');
const ProjectProjectFundingPage = () =>
  import('@/modules/project-funding/components/project-funding-form-project-page.vue');
const ProjectProjectFundingViewPage = () =>
  import('@/modules/project-funding/components/project-funding-view-page.vue');
const ProjectGanttPage = () => import('@/modules/project-gantt/components/project-gantt-form-page.vue');
const ProjectGanttViewPage = () => import('@/modules/project-gantt/components/project-gantt-view-page.vue');
const ProjectImplementationPage = () =>
  import('@/modules/project-implementation/components/project-implementation-form-project-page.vue');
const ProjectImplementationViewPage = () =>
  import('@/modules/project-implementation/components/project-implementation-view-page.vue');
const ProjectReportPage = () =>
  import('@/modules/project-report/components/project-report-form-project-page.vue');
const ProjectReportViewPage = () =>
  import('@/modules/project-report/components/project-report-view-page.vue');
const ProjectCompletionPage = () =>
  import('@/modules/project-completion/components/project-completion-form-project-page.vue');
const ProjectCompletionViewPage = () =>
  import('@/modules/project-completion/components/project-completion-view-page.vue');



export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'project',
        path: '/project',
        component: ProjectListPage,
        meta: {
          auth: true,
          permission: Permissions.values.projectRead,
        },
      },
      {
        path: '/project/:id/edit',
        component: ProjectFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.projectEdit,
        },
        children: [
          {
            name: 'create',
            path: 'create',
            component: ProjectCreatePage,
            meta: {
              auth: true,
              permission: Permissions.values.projectEdit,
              projectRoute: true,
              id: 0,
            },
          },
          {
            name: 'release',
            path: 'release',
            component: ProjectReleasePage,
            meta: {
              auth: true,
              permission: Permissions.values.projectEdit,
              projectRoute: true,
              id: 1,
            },
          },
          {
            name: 'funding',
            path: 'funding',
            component: ProjectProjectFundingPage,
            meta: {
              auth: true,
              permission: Permissions.values.projectEdit,
              projectRoute: true,
              id: 2,
            },
          },
          {
            name: 'gantt',
            path: 'gantt',
            component: ProjectGanttPage,
            meta: {
              auth: true,
              permission: Permissions.values.projectEdit,
              projectRoute: true,
              id: 3,
            },
          },
          {
            name: 'implementation',
            path: 'implementation',
            component: ProjectImplementationPage,
            meta: {
              auth: true,
              permission: Permissions.values.projectEdit,
              projectRoute: true,
              id: 4,
            },
          },
          {
            name: 'report',
            path: 'report',
            component: ProjectReportPage,
            meta: {
              auth: true,
              permission: Permissions.values.projectEdit,
              projectRoute: true,
              id: 5
            },
          },
          {
            name: 'completion',
            path: 'completion',
            component: ProjectCompletionPage,
            meta: {
              auth: true,
              permission: Permissions.values.projectEdit,
              projectRoute: true,
              id: 6,
            },
          },

        ],
        props: true,
      },
      {
        name: 'projectView',
        path: '/project/:id',
        component: ProjectViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.projectRead,
        },
        children: [
          {
            name: 'projectCreateView',
            path: 'create',
            component: ProjectCreateViewPage,
          },
          {
            name: 'projectReleaseView',
            path: 'release',
            component: ProjectReleaseViewPage,
          },
          {
            name: 'projectFundingView',
            path: 'project-funding',
            component: ProjectProjectFundingViewPage,
          },
          {
            name: 'projectGanttView',
            path: 'project-gantt',
            component: ProjectGanttViewPage,
          },
          {
            name: 'projectImplementationView',
            path: 'implementation',
            component: ProjectImplementationViewPage,
          },
          {
            name: 'projectReportView',
            path: 'report',
            component: ProjectReportViewPage,
          },
          {
            name: 'projectCompletionView',
            path: 'completion',
            component: ProjectCompletionViewPage,
          },
        ],
        props: true,
      },
    ],
  },
];
