import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class OutpatientClinicPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.outpatientClinicRead,
    );
    this.outpatientClinicAutocomplete = permissionChecker.match(
      Permissions.values.outpatientClinicAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.outpatientClinicCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.outpatientClinicEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.outpatientClinicDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.outpatientClinicNavigate,
    );
  }
}
