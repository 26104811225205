import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const LegalDepartmentListPage = () =>
  import('@/modules/legal-department/components/legal-department-list-page.vue');
const LegalDepartmentFormPage = () =>
  import('@/modules/legal-department/components/legal-department-form-page.vue');
const LegalDepartmentViewPage = () =>
  import('@/modules/legal-department/components/legal-department-view-page.vue');


export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'legalDepartment',
        path: '/legal-department',
        component: LegalDepartmentListPage,
        meta: {
          auth: true,
          permission: Permissions.values.legalDepartmentNavigate,
        },
      },
      {
        name: 'legalDepartmentNew',
        path: '/legal-department/new',
        component: LegalDepartmentFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.legalDepartmentNavigate,
        },
      },
      {
        name: 'legalDepartmentEdit',
        path: '/legal-department/:id/edit',
        component: LegalDepartmentFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.legalDepartmentNavigate,
        },
        props: true,
      },
      {
        name: 'legalDepartmentView',
        path: '/legal-department/:id',
        component: LegalDepartmentViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.legalDepartmentNavigate,
        },
        props: true,
      },
    ],
  },
];
