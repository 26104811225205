import projectReleaseRequestListStore from '@/modules/project-release-request/project-release-request-list-store';
import projectReleaseRequestViewStore from '@/modules/project-release-request/project-release-request-view-store';
import projectReleaseRequestFormStore from '@/modules/project-release-request/project-release-request-form-store';

export default {
  namespaced: true,

  modules: {    
    form: projectReleaseRequestFormStore,
    list: projectReleaseRequestListStore,
    view: projectReleaseRequestViewStore,
  },
};
