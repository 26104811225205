<template>
  <div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
    >
      <el-form-item
        :label="fields.fundingAgency.label"
        :prop="fields.fundingAgency.name"
        :required="fields.fundingAgency.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <app-funding-agency-autocomplete-input
            :fetchFn="fields.fundingAgency.fetchFn"
            :mapperFn="fields.fundingAgency.mapperFn"
            :parentId="projectCreateId"
            :showCreate="!modal"
            v-model="model[fields.fundingAgency.name]"
            mode="single"
          ></app-funding-agency-autocomplete-input>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.contactPerson.label"
        :prop="fields.contactPerson.name"
        :required="fields.contactPerson.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <el-input v-model="model[fields.contactPerson.name]" />
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.tenderUrl.label"
        :prop="fields.tenderUrl.name"
        :required="fields.tenderUrl.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <el-input v-model="model[fields.tenderUrl.name]" />
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.deadlineForSubmission.label"
        :prop="fields.deadlineForSubmission.name"
        :required="fields.deadlineForSubmission.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <el-date-picker
            :format="elementUiDateFormat"
            placeholder
            type="date"
            v-model="model[fields.deadlineForSubmission.name]"
          ></el-date-picker>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.availabilityOfGrantorDecision.label"
        :prop="fields.availabilityOfGrantorDecision.name"
        :required="fields.availabilityOfGrantorDecision.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <el-date-picker
            :format="elementUiDateFormat"
            placeholder
            type="date"
            v-model="model[fields.availabilityOfGrantorDecision.name]"
          ></el-date-picker>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.decision.label"
        :prop="fields.decision.name"
        :required="fields.decision.required"
      >
        <el-col :lg="3" :md="6" :sm="8">
          <el-select v-model="model[fields.decision.name]">
            <el-option :value="undefined">--</el-option>
            <el-option
              :key="option.id"
              :label="option.label"
              :value="option.id"
              v-for="option in fields.decision.options"
            ></el-option>
          </el-select>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.amount.label"
        :prop="fields.amount.name"
        :required="fields.amount.required"
        v-if="model.decision === 'positive'"
      >
        <el-col :lg="3" :md="6" :sm="8">
          <el-input-number
            :controls="false"
            :precision="fields.amount.scale"
            v-model="model[fields.amount.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.decisionFile.label"
        :prop="fields.decisionFile.name"
        :required="isDecisionSet"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <app-file-upload
            :max="fields.decisionFile.max"
            :storage="fields.decisionFile.storage"
            :formats="fields.decisionFile.formats"
            :permissions="fields.decisionFile.permissions"
            v-model="model[fields.decisionFile.name]"
          ></app-file-upload>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.projectDuration.label"
        :prop="fields.projectDuration.name"
        :required="fields.projectDuration.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <el-date-picker
            :format="elementUiDateFormat"
            unlink-panelss
            placeholder
            type="daterange"
            v-model="model[fields.projectDuration.name]"
          ></el-date-picker>
        </el-col>
      </el-form-item>
      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
          >
            <app-i18n code="common.save"></app-i18n>
          </el-button>
          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
          >
            <app-i18n code="common.cancel"></app-i18n>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { GrantModel } from '@/modules/grant/grant-model';

const { fields } = GrantModel;
const formSchema = new FormSchema([
  fields.id,
  fields.projectFunding,
  fields.fundingAgency,
  fields.contactPerson,
  fields.tenderUrl,
  fields.deadlineForSubmission,
  fields.availabilityOfGrantorDecision,
  fields.projectDuration,
  fields.decision,
  fields.amount,
  fields.decisionFile,
  fields.updatedAt,
]);

export default {
  name: 'app-grant-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
      projectCreateId: 'project/form/projectCreateId',
    }),

    fields() {
      return fields;
    },

    isDecisionSet() {
      return !!this.model.decision;
    },
  },

  watch: {
    record: function() {
      this.doUpdateModel();
    },
    model: {
      deep: true,
      handler: function() {
        this.$store.dispatch('grant/form/doSetFormModel', this.model);
      },
    },
  },

  methods: {
    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },

    doUpdateModel() {
      this.model = formSchema.initialValues(this.record || {});
    },
  },
};
</script>

<style></style>
