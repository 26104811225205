import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const ResearchFocusListPage = () =>
  import('@/modules/research-focus/components/research-focus-list-page.vue');
const ResearchFocusFormPage = () =>
  import('@/modules/research-focus/components/research-focus-form-page.vue');
const ResearchFocusViewPage = () =>
  import('@/modules/research-focus/components/research-focus-view-page.vue');
const ResearchFocusImporterPage = () =>
  import('@/modules/research-focus/components/research-focus-importer-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'researchFocus',
        path: '/research-focus',
        component: ResearchFocusListPage,
        meta: {
          auth: true,
          permission: Permissions.values.researchFocusNavigate,
        },
      },
      {
        name: 'researchFocusNew',
        path: '/research-focus/new',
        component: ResearchFocusFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.researchFocusNavigate,
        },
      },
      {
        name: 'researchFocusImporter',
        path: '/research-focus/import',
        component: ResearchFocusImporterPage,
        meta: {
          auth: true,
          permission: Permissions.values.researchFocusImport,
        },
      },
      {
        name: 'researchFocusEdit',
        path: '/research-focus/:id/edit',
        component: ResearchFocusFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.researchFocusEdit,
        },
        props: true,
      },
      {
        name: 'researchFocusView',
        path: '/research-focus/:id',
        component: ResearchFocusViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.researchFocusRead,
        },
        props: true,
      },
    ],
  },
];
