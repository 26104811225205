import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const SubjectAreaListPage = () =>
  import('@/modules/subject-area/components/subject-area-list-page.vue');
const SubjectAreaFormPage = () =>
  import('@/modules/subject-area/components/subject-area-form-page.vue');
const SubjectAreaViewPage = () =>
  import('@/modules/subject-area/components/subject-area-view-page.vue');
const SubjectAreaImporterPage = () =>
  import('@/modules/subject-area/components/subject-area-importer-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'subjectArea',
        path: '/subject-area',
        component: SubjectAreaListPage,
        meta: {
          auth: true,
          permission: Permissions.values.subjectAreaNavigate,
        },
      },
      {
        name: 'subjectAreaNew',
        path: '/subject-area/new',
        component: SubjectAreaFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.subjectAreaNavigate,
        },
      },
      {
        name: 'subjectAreaImporter',
        path: '/subject-area/import',
        component: SubjectAreaImporterPage,
        meta: {
          auth: true,
          permission: Permissions.values.subjectAreaImport,
        },
      },
      {
        name: 'subjectAreaEdit',
        path: '/subject-area/:id/edit',
        component: SubjectAreaFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.subjectAreaEdit,
        },
        props: true,
      },
      {
        name: 'subjectAreaView',
        path: '/subject-area/:id',
        component: SubjectAreaViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.subjectAreaRead,
        },
        props: true,
      },
    ],
  },
];
