import chancellorListStore from '@/modules/chancellor/chancellor-list-store';
import chancellorViewStore from '@/modules/chancellor/chancellor-view-store';
import chancellorFormStore from '@/modules/chancellor/chancellor-form-store';
import chancellorDestroyStore from '@/modules/chancellor/chancellor-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: chancellorDestroyStore,
    form: chancellorFormStore,
    list: chancellorListStore,
    view: chancellorViewStore,    
  },
};
