/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
module.exports = class  Storage {
  static get values() {
    return {
      userProfileAvatar: {
        id: 'userProfileAvatar',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 1 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },

      projectCreateTimeSheet: {
        id: 'projectCreateTimeSheet',
        folder: 'projectCreate/timeSheet',
        maxSizeInBytes: 20 * 1024 * 1024,
      },
      
      projectCreateCostsPlan: {
        id: 'projectCreateCostsPlan',
        folder: 'projectCreate/costsPlan',
        maxSizeInBytes: 20 * 1024 * 1024,
      },

      projectCreateDescriptionThirdPartyFunding: {
        id: 'projectCreateDescriptionThirdPartyFunding',
        folder: 'projectCreate/descriptionThirdPartyFunding',
        maxSizeInBytes: 20 * 1024 * 1024,
      },
      
      projectCreateFinancialGuideline: {
        id: 'projectCreateFinancialGuideline',
        folder: 'projectCreate/financialGuideline',
        maxSizeInBytes: 20 * 1024 * 1024,
      },

      projectReleaseSignedDocument: {
        id: 'projectReleaseSignedDocument',
        folder: 'projectRelease/signedDocument',
        maxSizeInBytes: 20 * 1024 * 1024,
      },

      projectGanttGanttChart: {
        id: 'projectGanttGanttChart',
        folder: 'projectGantt/ganttChart',
        maxSizeInBytes: 20 * 1024 * 1024,
      },

      projectImplementationEthicsCommitteeDecision: {
        id: 'projectImplementationEthicsCommitteeDecision',
        folder: 'projectImplementation/ethicsCommitteeDecision',
        maxSizeInBytes: 20 * 1024 * 1024,
      },

      projectReportReportFile: {
        id: 'projectReportReportFile',
        folder: 'projectReport/reportFile',
        maxSizeInBytes: 20 * 1024 * 1024,
      },

      projectReportInternalReportFile: {
        id: 'projectReportInternalReportFile',
        folder: 'projectReport/internalReportFile',
        maxSizeInBytes: 20 * 1024 * 1024,
      },

      projectReportGrantorsExtensionApprovalFile: {
        id: 'projectReportGrantorsExtensionApprovalFile',
        folder: 'projectReport/grantorsExtensionApprovalFile',
        maxSizeInBytes: 20 * 1024 * 1024,
      },

      projectCompletionFinalReportFile: {
        id: 'projectCompletionFinalReportFile',
        folder: 'projectCompletion/finalReportFile',
        maxSizeInBytes: 20 * 1024 * 1024,
      },

      projectCompletionFinalProjectBudgetAndFunding: {
        id: 'projectCompletionFinalProjectBudgetAndFunding',
        folder: 'projectCompletion/finalProjectBudgetAndFunding',
        maxSizeInBytes: 20 * 1024 * 1024,
      },

      cooperationPartnerContractContract: {
        id: 'cooperationPartnerContractContract',
        folder: 'cooperationPartnerContract/contract',
        maxSizeInBytes: 20 * 1024 * 1024,
      },

      cooperationPartnerContractContractDraft: {
        id: 'cooperationPartnerContractContractDraft',
        folder: 'cooperationPartnerContract/contractDraft',
        maxSizeInBytes: 20 * 1024 * 1024,
      },

      employmentContractContract: {
        id: 'employmentContractContract',
        folder: 'employmentContract/contract',
        maxSizeInBytes: 20 * 1024 * 1024,
      },
      
      employmentContractContractDraft: {
        id: 'employmentContractContractDraft',
        folder: 'employmentContract/contractDraft',
        maxSizeInBytes: 10485760,
      },

      grantDecisionFile: {
        id: 'grantDecisionFile',
        folder: 'grant/decisionFile',
        maxSizeInBytes: 10485760,
      },

      templateTemplate: {
        id: 'templateTemplate',
        folder: 'template/template',
        maxSizeInBytes: 20 * 1024 * 1024,
      },

      knowledgeBaseDocument: {
        id: 'knowledgeBaseDocument',
        folder: 'knowledgeBase/document',
        maxSizeInBytes: 20 * 1024 * 1024,
      },
    };
  }
}
