import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class EmploymentContractPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.employmentContractRead,
    );
    this.employmentContractAutocomplete = permissionChecker.match(
      Permissions.values.employmentContractAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.employmentContractCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.employmentContractEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.employmentContractDestroy,
    );
    this.release = permissionChecker.match(
      Permissions.values.employmentContractRelease,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.employmentContractNavigate,
    );
  }
}
