import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class ProjectPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.projectRead,
    );
    this.create = permissionChecker.match(
      Permissions.values.projectCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.projectEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.projectDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.projectNavigate,
    );
  }
}
