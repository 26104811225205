import authAxios from '@/shared/axios/auth-axios';

export class ProjectService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/project/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/project`, {
      params,
    });

    return response.data;
  }

  static async create() {

    const response = await authAxios.post(
      `/project`,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/project/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/project`, {
      params,
    });

    return response.data;
  }

  static async close(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/project/close/${id}`,
      body
    );

    return response.data;
  }
}
